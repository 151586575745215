import { useEffect, useState, type ReactElement } from 'react';

import Chat from 'chat/Chat';
import ModalController from 'common/modal/ModalController';

import { modalControllerAtom } from 'atoms/atomModalController';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import { AuthenticationService } from 'services/authentication/AuthenticationService';

import { Portal } from '@mui/base';
import { Alert, Box, Snackbar } from '@mui/material';
import { notifyMessageAtom } from 'atoms/atomMessageError';
import { decodeJwt } from 'jose';
import { Cookie } from 'utils/cookie';
import { HOME_VIEW_TYPES, MODAL_TYPES } from 'common/interfaces/enums';
import NavBar from './components/navBar/Navbar';
import { chatActiveAtom, homeViewRenderAtom } from './atoms/AtomChatActive';
import RenderViewManager from './components/renderView/RenderViewManager';
import './Home.scss';

const Home = (): ReactElement => {
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const authenticationService = AuthenticationService.getInstance();

  const viewRender = useRecoilValue(homeViewRenderAtom);
  const [chatActive, setChatActive] = useRecoilState(chatActiveAtom);
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);

  const snackBar = useRecoilValue(notifyMessageAtom);
  const resetNotifyMessage = useResetRecoilState(notifyMessageAtom);

  const [validToken, setValidToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('token') && searchParams.has('mode')) {
      setLoading(false);
      setModalController({ type: MODAL_TYPES.RESET_PASSWORD });
    } else {
      void validateToken();
    }
    if (!chatActive) {
      localStorage.removeItem('lastMessageStorage');
    }
    const handleBeforeUnload = (e: BeforeUnloadEvent): boolean => {
      e.preventDefault();
      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chatActive, validToken]);

  async function validateToken(): Promise<void> {
    const token = Cookie.get('token');

    if (token !== undefined && !validToken) {
      const { exp } = decodeJwt(token);

      if (exp !== undefined && exp * 1000 < Date.now()) {
        Cookie.remove('token');
        setLoading(false);
        if (!chatActive && modalController.type === MODAL_TYPES.CLOSE) {
          setModalController({ type: MODAL_TYPES.START_CHAT });
        }
      } else {
        await authServiceTokenValidation(token);
      }
    } else {
      if (!chatActive && modalController.type === MODAL_TYPES.CLOSE) {
        setModalController({ type: MODAL_TYPES.START_CHAT });
      }
      setLoading(false);
    }
  }

  async function authServiceTokenValidation(token: string): Promise<void> {
    setLoading(true);
    await authenticationService
      .authenticateByToken(token)
      .then(() => {
        assistantApiSocketClient.setAuth(token);
        authenticationService.setAuthToken(token);
        setValidToken(true);
        if (!chatActive) {
          setChatActive(true);
          setLoading(false);
        }
      })
      .catch(() => {
        Cookie.remove('token');
        setLoading(false);
        setValidToken(() => false);
      });
  }

  return (
    <Box id="home-container">
      <NavBar
        disabled={modalController.type === MODAL_TYPES.START_CHAT}
        user={authenticationService.userData}
        setValidToken={setValidToken}
      />
      {!loading ? (
        <Box id="home-container__content">
          {chatActive && viewRender.type !== HOME_VIEW_TYPES.CHAT ? (
            <Box id="home-container__content__myspace">
              <RenderViewManager />
            </Box>
          ) : null}
          {chatActive ? <Chat /> : null}
          <Portal>
            <Snackbar
              open={snackBar.open}
              autoHideDuration={10000}
              onClose={() => {
                resetNotifyMessage();
              }}
            >
              <Alert severity={snackBar.status}>{snackBar.message}</Alert>
            </Snackbar>
          </Portal>
          <ModalController />
        </Box>
      ) : null}
    </Box>
  );
};

export default Home;
