import type { FilterOptions } from 'bulkPredictions/atoms/atomPreviewfilersState';
import type { Asset } from 'bulkPredictions/components/summary-layer/SummaryLayer';
import type { Option } from 'chat/interfaces/messages';
import type { DisplayTableData } from 'common/interfaces/interfaces';
import { Filter } from 'models/Filter';

export const findElementInArray = (
  array: string[] | undefined,
  element: string
): string | undefined => {
  if (array !== undefined && array.length > 0) {
    return array.find((arrayElement) => arrayElement === element);
  }
  return undefined;
};

export const getColumnOptions = (
  previewRows: DisplayTableData,
  asset: Asset | undefined
): Option[] => {
  const newOptions: Option[] = [];
  previewRows.keys?.forEach((key, index) => {
    let type = 'textual';
    let nameColumn = key;
    if (
      asset?.targetVariable !== undefined &&
      typeof asset.targetVariable === 'string'
    ) {
      const predictedKey = 'predicted_'.concat(asset.targetVariable);
      nameColumn = key === predictedKey ? asset?.targetVariable : key;
    }
    if (findElementInArray(asset?.features?.binary, nameColumn) !== undefined) {
      type = 'binary categorical';
    }
    if (
      findElementInArray(asset?.features?.categorical, nameColumn) !== undefined
    ) {
      type = 'categorical';
    }
    if (
      findElementInArray(asset?.features?.datetime, nameColumn) !== undefined
    ) {
      type = 'datetime';
    }
    if (
      findElementInArray(asset?.features?.numerical, nameColumn) !== undefined
    ) {
      type = 'numerical';
    }
    let sampleData;
    if (
      asset?.features?.sample_data?.[nameColumn] !== undefined &&
      (type === 'numerical' ||
        type === 'categorical' ||
        type === 'binary categorical')
    ) {
      sampleData = asset?.features?.sample_data[nameColumn];
    }
    newOptions.push({ id: index, value: key, label: key, type, sampleData });
  });
  return newOptions;
};

export const updateFilterValue = (
  filter: Filter | undefined
): Filter | undefined => {
  if (filter?.value !== undefined) {
    return new Filter({ ...filter, value: filter.value });
  } else if (filter instanceof Filter) {
    return new Filter({ ...filter });
  }
  return undefined;
};

export const getResizeTableDataByfilters = (
  currentOptions: FilterOptions,
  pastOptions: string | undefined
): { isPageUpdate: boolean; isFilterUpdate: boolean } => {
  let isPageUpdate = false;
  let isFilterUpdate = pastOptions === undefined;

  if (pastOptions !== undefined) {
    const oldFilters = JSON.parse(pastOptions);
    isFilterUpdate =
      currentOptions.filter !== undefined &&
      (oldFilters.filter?.name === undefined ||
        JSON.stringify(currentOptions.filter) !==
          JSON.stringify(oldFilters.filter));

    isPageUpdate =
      !isFilterUpdate &&
      (oldFilters.page !== currentOptions.page ||
        oldFilters.pageSize !== currentOptions.pageSize);
  }
  return { isPageUpdate, isFilterUpdate };
};
