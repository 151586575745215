import { Box, Typography } from '@mui/material';
import { useState, type ReactElement } from 'react';
import type { ContextData } from '../../UploadManager';

import type {
  ActionControllerValues,
  DatasetSchema,
  NotifyBoxTypes
} from 'common/interfaces/interfaces';
import PreviewDataset from 'featureEngineering/previewDataset/PreviewDataset';
import NotifyBox from 'common/notifyBox/NotifyBox';
import { contextSelectionAtom } from 'mySpace/uploadManager/atoms/atomContextSelections';
import { useRecoilState } from 'recoil';

import 'common/Common.scss';
import './UploadContext.scss';
import '../../UploadManager.scss';

const UploadContext = ({
  preview,
  contextData,
  title
}: {
  preview: DatasetSchema[];
  contextData: React.MutableRefObject<ContextData>;
  title?: string;
}): ReactElement => {
  const [messageError, setMessageError] = useState<null | NotifyBoxTypes>(null);
  const [contextSelection, setContextSelection] =
    useRecoilState(contextSelectionAtom);

  const clickAction = (values?: ActionControllerValues): void => {
    if (values !== undefined && 'index' in values) {
      let { index } = values;
      index = Number(index);
      let outputsChecked = 1;
      contextData.current.forEach((existingSelection, ind) => {
        if (contextSelection[ind].checked) {
          outputsChecked++;
        }
      });

      if (
        contextData.current[index] !== undefined &&
        !contextSelection[index].checked &&
        outputsChecked > 5
      ) {
        setMessageError({
          type: 'info',
          message: 'Select up to a maximum of 5 output variables'
        });
      } else if (contextSelection[index].checked) {
        setContextSelection([
          ...contextSelection.slice(0, index),
          { ...contextSelection[index], checked: false },
          ...contextSelection.slice(index + 1)
        ]);
      } else {
        setContextSelection([
          ...contextSelection.slice(0, index),
          { ...contextSelection[index], checked: true },
          ...contextSelection.slice(index + 1)
        ]);
      }
    }
  };

  return (
    <Box className="context">
      <Box className="upload__content__title">
        {title !== undefined ? title : null}
        <Typography className="upload__content__description">
          Select your target output columns (maximum 5) that would be used to
          create the insights and provide context for each column to ensure
          accurate analysis and meaningful insights from your data.
        </Typography>
      </Box>
      <PreviewDataset
        displayTableData={preview}
        options={{
          clickAction,
          createContext: contextData
        }}
      />
      <Box className="context__footer">
        <Box className="context__legend">
          <Box>
            <span id="succeed-span" /> Context added
          </Box>
          <Box>
            <span id="warning-span" /> Missing context
          </Box>
          <Box>
            <span id="error-span" /> Missing mandatory context
          </Box>
        </Box>
        {messageError !== null ? (
          <Box className="context__info">
            <NotifyBox
              type={messageError.type}
              message={messageError.message}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default UploadContext;
