import { ChevronLeft } from '@mui/icons-material';
import { Box, InputLabel, TextField, Typography } from '@mui/material';
import {
  USER_TRACKING_FENG_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomIcon from 'common/CustomIcon';
import CustomButton from 'common/button/CustomButton';
import { ICON_TYPE } from 'common/interfaces/enums';
import type {
  DatasetSchema,
  SendTransformation,
  DisplayTableData
} from 'common/interfaces/interfaces';
import {
  MENU,
  SUB_MENU,
  TRANSFORM_COLUMN
} from 'featureEngineering/featureEngineeringInterface';
import {
  modifyOriginalPreviewDatas,
  transformAction
} from 'featureEngineering/utils/transformationUtils';
import {
  FeatureType,
  FeatureTypeBigQuery
} from 'playground/interfaces/playground';
import {
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { GAUserEvent } from 'utils/utils';
import {
  getFormattedValues,
  newColumnOperationAtom,
  operationBuilderModalAtom
} from '../atoms/atomNewColumn';
import {
  activeTransformViewAtom,
  transformFeatureStateAtom
} from '../atoms/atomTransformFeature';
import './NewColumn.scss';
import OperationBuilder from './OperationBuilder';

interface NewColumnProps {
  columns: DatasetSchema[] | undefined;
  previewController: [
    DatasetSchema[] | undefined,
    Dispatch<SetStateAction<DatasetSchema[] | undefined>>
  ];
  rowsController: [
    DisplayTableData | undefined,
    Dispatch<SetStateAction<DisplayTableData | undefined>>
  ];
  scrollNewColumnIntoView: () => void;
}

const NewColumn = ({
  columns,
  previewController,
  rowsController,
  scrollNewColumnIntoView
}: NewColumnProps): ReactElement => {
  const setActiveTransformView = useSetRecoilState(activeTransformViewAtom);
  const setOpenBuilder = useSetRecoilState(operationBuilderModalAtom);
  const [operation, setOperation] = useRecoilState(newColumnOperationAtom);
  const [transformationState, setTransformState] = useRecoilState(
    transformFeatureStateAtom
  );
  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const formattedValues = useRecoilValue(getFormattedValues);
  const [newColumn, setNewColumn] = useState<string>('');
  const [preview, setPreview] = previewController;
  const [rowsPreview, setRowsPreview] = rowsController;

  const NEW_COLUMN_PLACEHOLDER = 'Type for new column name';
  const NEW_COLUMN_LABEL =
    'Name your new column and use the formula below to generate your desired combination.';
  const OPERATION_LABEL =
    'The formula builder allows you to select columns to be added, add operations and organize the operation as required.';

  const updateTransformationState = (): void => {
    const operationTransformation: SendTransformation = {
      action: SUB_MENU.NEW_COLUMN,
      column: formattedValues.otherColumns[0],
      type: FeatureType.NUMERICAL,
      params: {
        new_column_name: newColumn,
        numerical: true,
        update_column: false,
        operation: formattedValues
      }
    };
    const newTransformation = transformAction(
      transformationState,
      newColumn,
      MENU.COMBINE_COLUMNS,
      SUB_MENU.NEW_COLUMN,
      operationTransformation
    )(false);

    setTransformState(newTransformation);
    setOperation([]);
    setNewColumn('');
    modifyOriginalPreviewDatas(
      transformationState,
      { preview, setPreview },
      { rowsPreview, setRowsPreview },
      {
        name: newColumn,
        index: preview === undefined ? 0 : preview.length,
        type: FeatureTypeBigQuery.INTEGER,
        data: {
          dataValue: FeatureType.NUMERICAL,
          sampleData: 0
        }
      },
      operationTransformation,
      SUB_MENU.NEW_COLUMN
    );
    scrollNewColumnIntoView();
    setActiveTransformView(TRANSFORM_COLUMN.DEFAULT);
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.NEW_COLUMN}`
    );
  };

  return (
    <Box className="transform-column-container">
      <Box className="navigation-title">
        <Box className="title">
          <CustomIcon type={ICON_TYPE.COMBINE01} />
          <p>Combine columns</p>
        </Box>
        <ChevronLeft
          className="cursor-pointer"
          onClick={() => {
            setActiveTransformView(TRANSFORM_COLUMN.DEFAULT);
          }}
        />
      </Box>
      <Box id="new-column-name">
        <InputLabel
          htmlFor="new-column-name"
          className="new-column-input-label"
        >
          {NEW_COLUMN_LABEL}
        </InputLabel>
        <TextField
          id="new-column-name"
          variant="outlined"
          fullWidth
          size="small"
          placeholder={NEW_COLUMN_PLACEHOLDER}
          onChange={(e) => {
            setNewColumn(e.target.value);
          }}
        />
      </Box>
      <Box id="operation-builder-view">
        <Box id="operation-builder-container">
          <Typography>Build your operation</Typography>
          <InputLabel
            htmlFor="operation-builder-field"
            className="new-column-input-label"
          >
            {OPERATION_LABEL}
          </InputLabel>
          <Box
            className="operation-wrapper"
            onClick={() => {
              setOpenBuilder(true);
            }}
          >
            <Box className="operation-builder-field operation-builder-summary">
              {operation.map((operation) => operation.value).join(' ')}
            </Box>
          </Box>
        </Box>
        <OperationBuilder columns={columns} />
      </Box>
      <CustomButton
        variant="primary"
        disabled={operation.length === 0 || newColumn === ''}
        style={{ maxWidth: 'none' }}
        onClick={() => {
          updateTransformationState();
        }}
      >
        Save
      </CustomButton>
    </Box>
  );
};

export default NewColumn;
