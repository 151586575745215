import type { FilterOptions } from 'bulkPredictions/atoms/atomPreviewfilersState';
import type { Row, DisplayTableData } from 'common/interfaces/interfaces';
import type { PreviewRowsProps } from 'featureEngineering/previewRows/PreviewRows';
import _ from 'lodash';
import type { MinMax } from 'models/Filter';

const filterByShowOnly = (filterOptions: FilterOptions): string | undefined => {
  if (filterOptions?.filter?.value !== undefined) {
    if (
      filterOptions?.filter?.type === 'numerical' &&
      typeof filterOptions.filter.value === 'object'
    ) {
      return 'numerical';
    } else if (
      filterOptions?.filter?.type === 'categorical' &&
      Array.isArray(filterOptions.filter.value)
    ) {
      return 'categorical';
    } else if (
      (filterOptions?.filter?.type === 'textual' ||
        filterOptions?.filter?.type === 'datetime') &&
      typeof filterOptions.filter.value === 'string'
    ) {
      return 'textual-datetime';
    }
  }
  return undefined;
};

const filterByNumericalData = (
  filterOptions: FilterOptions,
  tableDataRows: Row[],
  showOnlyName: string
): Row[] => {
  let newTableRows = tableDataRows;
  const showOnlyValue = filterOptions?.filter?.value as MinMax;

  const getItemsFromNumericalsProperty = (array: Row[]): Row[] => {
    return array.filter((row) => {
      const valueNameColumn = row[showOnlyName];
      const showOnlyValueMinProp = showOnlyValue.min;
      const showOnlyValueMaxProp = showOnlyValue.max;
      if (
        valueNameColumn !== undefined &&
        valueNameColumn !== null &&
        showOnlyValueMinProp !== undefined &&
        showOnlyValueMaxProp !== undefined &&
        Number(valueNameColumn) >= Number(showOnlyValueMinProp) &&
        Number(valueNameColumn) <= Number(showOnlyValueMaxProp)
      ) {
        return row;
      }
      return false;
    });
  };

  const getItemFromDefinedNumericalProperty = (
    array: Row[],
    property: 'min' | 'max'
  ): Row[] => {
    return array.filter((row) => {
      const valueNameColumn = row[showOnlyName];
      const showOnlyValueProp = showOnlyValue[property];
      if (
        valueNameColumn !== undefined &&
        valueNameColumn !== null &&
        showOnlyValueProp !== undefined &&
        ((property === 'min' &&
          Number(valueNameColumn) >= Number(showOnlyValueProp)) ||
          (property === 'max' &&
            Number(valueNameColumn) <= Number(showOnlyValueProp)))
      ) {
        return row;
      }
      return false;
    });
  };

  if (showOnlyValue.min !== undefined && showOnlyValue.max !== undefined) {
    newTableRows = getItemsFromNumericalsProperty(newTableRows);
  } else if (showOnlyValue.min !== undefined) {
    newTableRows = getItemFromDefinedNumericalProperty(newTableRows, 'min');
  } else if (showOnlyValue.max !== undefined) {
    newTableRows = getItemFromDefinedNumericalProperty(newTableRows, 'max');
  }
  return newTableRows;
};

export const filterTableRowsByShowOnlyCategories = (
  filterOptions: FilterOptions,
  tableDataRows: Row[]
): Row[] => {
  let result = tableDataRows;
  if (
    filterOptions?.filter?.name !== undefined &&
    filterOptions?.filter?.type !== undefined
  ) {
    const showOnlyName = filterOptions.filter.name;
    const typeOfShowOnly = filterByShowOnly(filterOptions);
    if (typeOfShowOnly === 'numerical') {
      result = filterByNumericalData(
        filterOptions,
        tableDataRows,
        showOnlyName
      );
    } else if (typeOfShowOnly === 'categorical') {
      const showOnlyValueArray = filterOptions?.filter?.value;
      if (Array.isArray(showOnlyValueArray) && showOnlyValueArray.length > 0) {
        result = tableDataRows.filter((row) => {
          const valueNameColumn = row[showOnlyName];
          return showOnlyValueArray.find(
            (value: string) => valueNameColumn === value
          );
        });
      }
    } else if (typeOfShowOnly === 'textual-datetime') {
      if (filterOptions.filter.value !== '') {
        const showOnlyValue = filterOptions?.filter?.value;
        result = tableDataRows.filter((row) => {
          const valueNameColumn = row[showOnlyName];
          return String(showOnlyValue) === String(valueNameColumn);
        });
      }
    }
  }
  return result;
};

export const updateTableRowsWithOrderBy = (
  filterOptions: FilterOptions,
  rows: Row[]
): Row[] => {
  let newRows = rows;
  if (filterOptions?.sortBy !== undefined) {
    const orderByColumn = filterOptions.sortBy;
    const mapedRows = rows.map((row) => {
      const newRow = row;
      if (newRow.id !== undefined) {
        newRow.id = Number(newRow.id);
      } else if (newRow.Id !== undefined) {
        newRow.Id = Number(newRow.Id);
      }
      return newRow;
    });
    const ascOrDesc = filterOptions?.order === 'desc' ? 'desc' : 'asc';
    newRows = _.orderBy(mapedRows, [orderByColumn], [ascOrDesc]);
  }
  return newRows;
};

export const cleanTablePredicate = (row: Row | undefined): row is Row =>
  row !== undefined;

export const defineInitialStatesByFilters = (
  displayTableData: DisplayTableData,
  filterOptions: FilterOptions,
  online: boolean
): {
  initialDatasetRows: Array<Row | undefined>;
  initialViewRows: Array<Row | undefined>;
} => {
  let initialDatasetRows = displayTableData.rows ?? [];
  let initialViewRows: Array<Row | undefined> = [];

  if (!online) {
    const safeTableDataRows =
      displayTableData?.rows?.filter(cleanTablePredicate);
    if (
      safeTableDataRows !== undefined &&
      displayTableData.rows.length > 0 &&
      displayTableData.keys !== undefined &&
      displayTableData.keys?.length > 0
    ) {
      const allRows = filterTableRowsByShowOnlyCategories(
        filterOptions,
        safeTableDataRows
      );
      const orderedRows = updateTableRowsWithOrderBy(filterOptions, allRows);
      if (orderedRows.length > 0) {
        initialDatasetRows = orderedRows;
      }
    } else if (
      displayTableData.rows.length > 0 &&
      displayTableData.keys === undefined &&
      initialDatasetRows.length === 0
    ) {
      initialViewRows = displayTableData.rows;
    }
  }
  return { initialDatasetRows, initialViewRows };
};

const getKeys = (
  displayTableData: DisplayTableData,
  options: PreviewRowsProps['options']
): string[] => {
  const keys: string[] = [];
  if (displayTableData.keys !== undefined && displayTableData.keys.length > 0) {
    return displayTableData.keys;
  }
  displayTableData.rows.filter(cleanTablePredicate).forEach((row) => {
    Object.keys(row).forEach((key) => keys.push(key));
  });
  let arrayKeys = Array.from(new Set(keys));
  if (options?.predictVariable !== undefined) {
    arrayKeys = arrayKeys.filter((key) => key !== options?.predictVariable);
  }
  return arrayKeys;
};

const orderKeysByKeyFactor = (
  keys: string[],
  filterOptions: FilterOptions
): string[] => {
  let newKeys = keys;
  if (filterOptions.keyFactor !== undefined) {
    newKeys = [filterOptions.keyFactor];
    keys.forEach((key) => newKeys.push(key));
    newKeys = Array.from(new Set(newKeys));
  }
  return newKeys;
};

export const filterColumnsByFactors = (
  displayTableData: DisplayTableData,
  filterOptions: FilterOptions,
  options: PreviewRowsProps['options']
): string[] => {
  let applyFilterKeys: string[] =
    displayTableData.keys ?? getKeys(displayTableData, options);
  if (filterOptions?.factorsDisplay !== undefined) {
    if (filterOptions.factorsDisplay.length > 0) {
      const lastKeys = getKeys(displayTableData, options);
      const newKeys: string[] = [];
      if (filterOptions?.keyFactor !== undefined) {
        newKeys.push(filterOptions.keyFactor);
      }
      filterOptions.factorsDisplay.forEach((factor) => newKeys.push(factor));
      if (options?.predictVariable !== undefined) {
        newKeys.push(`predicted_${options?.predictVariable}`);
      }
      const probabilityKey = lastKeys.find((key) =>
        key.startsWith('probability')
      );
      if (probabilityKey !== undefined) {
        newKeys.push(probabilityKey);
      }
      applyFilterKeys = Array.from(new Set(newKeys));
    }
  }
  return orderKeysByKeyFactor(applyFilterKeys, filterOptions);
};
