import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import BulkPredictions from 'bulkPredictions/bulkPredictions/BulkPredictions';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import { HOME_VIEW_TYPES } from 'common/interfaces/enums';
import type { Dataset, Model } from 'common/interfaces/interfaces';
import Transformation from 'featureEngineering/Transformation';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import Insights from 'insights/Insights';
import MergeDatasets from 'mergeDatasets/MergeDatasets';
import MergeDatasetsPreview, {
  type MergeDatasetsPreviewProps
} from 'mergeDatasets/mergePreview/MergeDatasetsPreview';
import CrossTabs from 'mySpace/crossTabs/CrossTabs';
import Details from 'mySpace/details/Details';
import Load from 'mySpace/load/Load';
import PlaygroundDataLayerRender from 'playground/renderLayer/PlaygroundDataLayerRender';
import type { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

const RenderViewManager = (): ReactElement => {
  const assistantApiService = AssistantApiService.getInstance();
  const viewRender = useRecoilValue(homeViewRenderAtom);
  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const deleteCountDown = (): void => {
    // Removes the timer that keeps track of the inactive user for features that require it
    const isOptimise = userLocationVariable.current.includes(
      USER_TRACKING_LOCATION_NAMES.OPTIMISE
    );
    if (isOptimise) {
      assistantApiService.deleteCountDown().catch(console.error);
    }
  };
  switch (viewRender.type) {
    case HOME_VIEW_TYPES.PLAYGROUND:
      deleteCountDown();
      return (
        <PlaygroundDataLayerRender
          selectedRowData={viewRender.payload?.selectedRowData}
          returnValues={viewRender.payload?.returnValues}
          filters={viewRender.payload?.filters}
        />
      );
    case HOME_VIEW_TYPES.MERGE_DATASETS: {
      const {
        selectedAssetsToMerge
      }: {
        selectedAssetsToMerge?: Dataset[];
      } = viewRender.payload;
      return (
        <MergeDatasets selectedAssetsToMerge={selectedAssetsToMerge ?? []} />
      );
    }
    case HOME_VIEW_TYPES.MERGE_DATASETS_PREVIEW: {
      deleteCountDown();
      const { displayTableData, keyIdentifiers }: MergeDatasetsPreviewProps =
        viewRender.payload;
      return (
        <MergeDatasetsPreview
          displayTableData={displayTableData}
          keyIdentifiers={keyIdentifiers}
        />
      );
    }
    case HOME_VIEW_TYPES.BULK_PREDICTIONS:
      deleteCountDown();
      return (
        <BulkPredictions
          asset={viewRender.payload.asset}
          selectedRowData={viewRender.payload.selectedRowData}
          playgroundReturnData={viewRender.payload.playgroundReturnData}
          newFilters={viewRender.payload.newFilters}
        />
      );
    case HOME_VIEW_TYPES.INSIGHTS:
      deleteCountDown();
      return (
        <Insights
          goal={viewRender.payload.goal}
          statement={viewRender.payload.statement}
          insights={viewRender.payload.insight}
          plot={viewRender.payload.plot}
          downloadURL={viewRender.payload.download_url}
          datasetId={viewRender.payload.dataset_id}
          originalDatasetId={viewRender.payload.original_dataset_id}
          features={viewRender.payload.features}
        />
      );
    case HOME_VIEW_TYPES.TRANSFORMATION:
      return <Transformation />;
    case HOME_VIEW_TYPES.CROSS_TABS:
      return (
        <CrossTabs
          datasetId={viewRender.payload.datasetId}
          mainGroup={viewRender.payload.mainGroup}
          viewId={viewRender.payload?.viewId}
        />
      );
    case HOME_VIEW_TYPES.DETAIL_MODEL:
    case HOME_VIEW_TYPES.DETAIL_DATASETS: {
      deleteCountDown();
      const {
        isInference,
        isView,
        openChat,
        asset
      }: {
        isView?: boolean;
        isInference?: boolean;
        openChat?: boolean;
        asset: Dataset | Model;
      } = viewRender.payload;
      return (
        <Details
          selectedAsset={asset}
          isInference={Boolean(isInference)}
          isView={Boolean(isView)}
          openChat={Boolean(openChat)}
        />
      );
    }
    case HOME_VIEW_TYPES.LOAD_MODELS:
    case HOME_VIEW_TYPES.LOAD_DATASETS:
      deleteCountDown();
      return (
        <Load isDataset={viewRender.type === HOME_VIEW_TYPES.LOAD_DATASETS} />
      );
  }
  return <></>;
};
export default RenderViewManager;
