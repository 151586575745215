import {
  Box,
  Chip,
  MenuItem,
  Pagination,
  Radio,
  Select,
  Typography
} from '@mui/material';
import {
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type ReactElement,
  type Ref
} from 'react';

import type {
  ActionControllerValues,
  AssetListResponse,
  Dataset,
  Model,
  PaginationStatus,
  Views
} from 'common/interfaces/interfaces';

import {
  ACCESS_TYPES,
  BACKEND_STRINGS,
  HOME_VIEW_TYPES,
  ICON_TYPE,
  MODAL_ACTIONS_ID,
  MODAL_INTENTS,
  MODAL_TYPES,
  RESTRICTIONS
} from 'common/interfaces/enums';

import Spinner from 'common/Spinner';
import SmallCheckbox from 'common/smallCheckbox/SmallCheckbox';

import { modalControllerAtom } from 'atoms/atomModalController';
import { lastResponseAtom } from 'chat/atoms/atomLastResponse';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import {
  GAUserEvent,
  curringDataSearchParameters,
  formatDate,
  lastMessageIsInference,
  sanitizeFilename
} from 'utils/utils';

import ColumnTableImage from 'assets/imgs/ai-set-2.png';
import CustomIcon from 'common/CustomIcon';

import { DeleteOutline, KeyboardArrowDown } from '@mui/icons-material';
import {
  createErrorNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import {
  USER_TRACKING_DISPLAY_ASSETS_ACTIONS,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import {
  EXTENSIONS_FILES,
  type EXTENSION_FILE
} from 'chat/magicIsland/components/preparingData/extensionFiles';
import CustomButton from 'common/button/CustomButton';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import RenderViewComposed from 'home/components/renderView/RenderView';
import _ from 'lodash';
import { uploadFile } from 'services/UploadService';
import CustomTable, {
  type HeaderRowProps,
  type TableRowProps
} from 'common/table/CustomTable';
import type { TableCellProps } from 'common/table/CustomTableCell';
import 'common/Common.scss';
import './Load.scss';

interface PaginationView {
  page: number;
  collectionSize: number;
  data: Dataset[];
  buttonTitle: string;
  disableButton: boolean;
}

const UPLOAD = 'UPLOAD_DATASET';
enum BULK_ACTIONS {
  MERGE_DATASETS = 'MERGE_DATASETS',
  DELETE_DATASETS = 'DELETE_DATASETS'
}

const Load = ({ isDataset }: { isDataset: boolean }): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);

  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const isOptimise = userLocationVariable.current.includes(
    USER_TRACKING_LOCATION_NAMES.OPTIMISE
  );

  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);
  const setModalController = useSetRecoilState(modalControllerAtom);
  const assistantService = AssistantApiService.getInstance();
  const exposeService = ExposeService.getInstance();
  const authenticationService = AuthenticationService.getInstance();

  const [assets, setAssets] = useState<Array<Dataset | Model>>([]);
  const [viewVariants, setViewVariants] = useState<Record<string, number>>({});
  const [paginationViews, setPaginationViews] = useState<
    Record<string, PaginationView>
  >({});

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const [pagination, setPagination] = useState({ actual: 0, total: 0 });

  const [selectMerges, setSelectMerges] = useState<boolean>(false);
  const [selectedAssetsToMerge, setSelectedAssetsToMerge] = useState<Dataset[]>(
    []
  );

  const [selectBulkDelete, setSelectBulkDelete] = useState<boolean>(false);
  const [selectedAssetsToDelete, setSelectedAssetsToDelete] = useState<
    Dataset[]
  >([]);

  const inputUploadLinkRef: Ref<HTMLInputElement> = useRef(null);

  let disableContinueButton = selectedAssetId === null;
  if (selectMerges) {
    disableContinueButton = selectedAssetsToMerge.length < 2;
  } else if (selectBulkDelete) {
    disableContinueButton = selectedAssetsToDelete.length === 0;
  }

  const radioButton = useRef<HTMLInputElement>(null);

  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );

  useEffect(() => {
    resetValues();
    if (loading && assets.length > 0) {
      setLoading(false);
    }

    if (
      viewRender.payload?.page !== undefined &&
      viewRender.payload.page !== 1
    ) {
      obtainData(viewRender.payload.page);
    } else if (
      viewRender.type === HOME_VIEW_TYPES.LOAD_MODELS ||
      viewRender.type === HOME_VIEW_TYPES.LOAD_DATASETS
    ) {
      obtainData(1);
    }
  }, [loading, isDataset, viewRender]);

  const showErrorAlert = (message?: string): void => {
    createErrorNotification(
      message ?? 'Something went wrong when uploading the selected file',
      setNotifyMessage
    );
  };

  const resetValues = (): void => {
    if (selectMerges) {
      setSelectMerges(false);
      if (selectedAssetsToMerge.length > 0) {
        setSelectedAssetsToMerge([]);
      }
    }
    if (selectBulkDelete) {
      setSelectBulkDelete(false);
      if (selectedAssetsToDelete.length > 0) {
        setSelectedAssetsToDelete([]);
      }
    }
    if (selectedAssetId !== null) {
      setSelectedAssetId(null);
    }
  };

  const obtainViewsByDatasetId = async (
    datasetId: string,
    page = 1,
    size = 2
  ): Promise<void> => {
    await exposeService
      .getViewsByDatasetId(datasetId, page, size, 'createdDate', 'desc')
      .then((data: Views) => {
        const newData = {
          ...paginationViews,
          [datasetId]: {
            page: page + 1,
            collectionSize: data.collectionSize,
            data: data.data,
            buttonTitle: 'Show more',
            disableButton: false
          }
        };
        if (newData[datasetId] !== undefined) {
          const dataBefore = JSON.parse(
            JSON.stringify(paginationViews[datasetId]?.data ?? [])
          );
          newData[datasetId].data = dataBefore.concat(data.data);
          const initialAmount = newData[datasetId].data.length + 2;
          const newbuttonTitle =
            initialAmount >= data.collectionSize ? 'Show less' : 'Show more';
          newData[datasetId].buttonTitle = newbuttonTitle;
        }
        setPaginationViews(newData);
      })
      .catch((error) => {
        console.log(error);
        showErrorAlert(error);
        const newData = {
          ...paginationViews,
          [datasetId]: {
            page: page + 1,
            collectionSize: paginationViews[datasetId]?.collectionSize,
            data: paginationViews[datasetId]?.data,
            buttonTitle: 'Show more',
            disableButton: false
          }
        };
        setPaginationViews(newData);
      });
  };

  const getViewsFromDataset = async (id: string): Promise<void> => {
    const viewsByDatasetId = paginationViews[id];
    const page = viewsByDatasetId !== undefined ? viewsByDatasetId.page : 2;
    const newData = {
      ...paginationViews,
      [id]: {
        page: page + 1,
        collectionSize: paginationViews[id]?.collectionSize,
        data: paginationViews[id]?.data,
        buttonTitle: 'Show more',
        disableButton: true
      }
    };
    setPaginationViews(newData);
    await obtainViewsByDatasetId(id, page, 2);
    setViewVariants({
      ...viewVariants,
      [id]: viewVariants[id] + 2
    });
  };

  //  TODO: check to move repeated code outside this changes into utils or remove some
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target;
    const fileObj = target.files !== null ? target.files[0] : undefined;
    if (fileObj === undefined) {
      return;
    }
    const typeFile = EXTENSIONS_FILES.find(
      (extension: EXTENSION_FILE) => extension.app === fileObj.type
    );
    if (typeFile !== undefined) {
      submitFile(fileObj);
    } else {
      showErrorAlert();
    }
  };

  const triggersForUploadModals = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${
        (isDataset
          ? USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET
          : USER_TRACKING_LOCATION_NAMES.UPLOAD_MODEL) as string
      }`
    );
    setUserLocationVariable({
      ...userLocationVariable,
      current: isDataset
        ? USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET
        : USER_TRACKING_LOCATION_NAMES.UPLOAD_MODEL,
      previous: userLocationVariable.current
    });
  };

  const submitFile = (file: File): void => {
    const renamedFile = sanitizeFilename(file);
    uploadFile(renamedFile)
      .then((signedUrl) => {
        exposeService
          .uploadAsset(signedUrl.uri, false)
          .then((uploadedDataReference) => {
            const { runId, fileId } = uploadedDataReference;
            if (runId !== undefined && fileId !== undefined) {
              triggersForUploadModals();
              setModalController({
                type: MODAL_TYPES.UPLOAD_MANAGER,
                payload: {
                  uri: signedUrl.uri,
                  fileId,
                  runId
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
            showErrorAlert();
          });
      })
      .catch((error) => {
        showErrorAlert();
        return error;
      });
  };

  const obtainData = (page: number): void => {
    const isShared = Boolean(sharedResources?.defaultValue);
    const filterOutShared = (assets: Model | Dataset): boolean => {
      if (isShared) {
        return true;
      }
      return (
        assets.visibility === ACCESS_TYPES.PRIVATE ||
        assets.visibility === ACCESS_TYPES.HIDDEN
      );
    };

    if (!isDataset) {
      exposeService
        .getUserModels(page)
        .then((modelReponse: AssetListResponse) => {
          const filteredData = modelReponse.data.filter(filterOutShared);
          setAssets(filteredData);
          updatePagination(
            page,
            modelReponse.collectionSize,
            modelReponse.data.length
          );
        })
        .catch(() => {
          setPagination({ actual: 0, total: 0 });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Make sure user is not accessing the nav bar my space
      // at the inference step in the conversation
      const tags =
        lastMessageIsInference(lastResponse) && isOptimise
          ? [BACKEND_STRINGS.INFERENCE_ASSET_TAG]
          : [];

      exposeService
        .getUserDatasets(page, 10, tags)
        .then((datasetResponse: AssetListResponse) => {
          const datasets = adaptDatasetsToAssets(
            datasetResponse.data as Dataset[]
          );
          const filteredData = datasets.filter(filterOutShared);
          setAssets(filteredData);
          updatePagination(
            page,
            datasetResponse.collectionSize,
            datasetResponse.data.length
          );
        })
        .catch(() => {
          setPagination({ actual: 0, total: 0 });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const adaptDatasetsToAssets = (data: Dataset[]): Dataset[] => {
    const lastCreatedDate = getLastDate(data);
    const datasetsWithCreatedDate = data.map((element: Dataset) => {
      if (element.createdDate === undefined) {
        if (lastCreatedDate === undefined) {
          element.createdDate = element.timeOfUsage;
        } else {
          element.createdDate = lastCreatedDate;
        }
      }

      if (element.visibility === ACCESS_TYPES.HIDDEN) {
        element.tags.push(ACCESS_TYPES.TEMPORARILY_STORED);
      }

      return element;
    });
    return datasetsWithCreatedDate;
  };

  const getLastDate = (data: Dataset[]): string | undefined => {
    let lastCreatedDate;
    const elementWithCreatedDate = data.find(
      (element: Dataset) =>
        element.createdDate !== undefined && element.createdDate !== ''
    );
    if (elementWithCreatedDate?.createdDate !== undefined) {
      const createdDate = new Date(elementWithCreatedDate.createdDate);
      createdDate.setDate(createdDate.getDate() - 1);
      lastCreatedDate = createdDate.toISOString();
    } else {
      const elementWithTimeOfUsage = data.find(
        (element: Dataset) =>
          element.timeOfUsage !== undefined && element.timeOfUsage !== ''
      );
      if (elementWithTimeOfUsage?.timeOfUsage !== undefined) {
        const createdDate = new Date(elementWithTimeOfUsage.timeOfUsage);
        createdDate.setDate(createdDate.getDate() - 1);
        lastCreatedDate = createdDate.toISOString();
      }
    }
    return lastCreatedDate;
  };

  const updatePagination = (
    page: number,
    collectionSize: number,
    dataLength: number
  ): void => {
    // The last page brings less data than the rest of the pages
    // so we dont want to update the total pages
    if (pagination.total !== page) {
      setPagination({
        actual: page,
        total: Math.ceil(collectionSize / dataLength)
      });
    } else {
      setPagination((prevPaginate: PaginationStatus) => {
        return { ...prevPaginate, actual: page };
      });
    }
  };

  const generateTags = (tags: string[]): ReactElement => {
    const getChipOfAsset = (tag: string, index: number): JSX.Element => {
      if (tag === ACCESS_TYPES.TEMPORARILY_STORED) {
        return (
          <Chip
            className="chip temporarily-stored"
            key={`${tag}-${index}`}
            label={tag}
          />
        );
      }
      return <Chip className="chip" key={`${tag}-${index}`} label={tag} />;
    };

    if (tags.length < 3) {
      return <Box className="tags">{tags.map(getChipOfAsset)}</Box>;
    }

    const chipsTags = tags.slice(0, 2);
    return (
      <Box className="tags">
        {[
          ...chipsTags.map(getChipOfAsset),
          <Chip className="chip" key={`dots-${2}`} label={'...'} />
        ]}
      </Box>
    );
  };

  const generateSpanDate = (asset: Dataset | Model): JSX.Element => {
    let componentSpan;
    if (
      'createdDate' in asset &&
      asset.createdDate !== undefined &&
      asset.createdDate !== ''
    ) {
      componentSpan = 'Date created: ' + formatDate(asset.createdDate);
    } else if (
      'timeOfUsage' in asset &&
      asset.timeOfUsage !== undefined &&
      asset.timeOfUsage !== ''
    ) {
      componentSpan = 'Last usage: ' + formatDate(asset.timeOfUsage);
    } else {
      return <></>;
    }
    return <span>{'· ' + componentSpan}</span>;
  };

  const handleRowSelection = (assetData?: ActionControllerValues): void => {
    if (assetData !== undefined && 'id' in assetData) {
      if (isDataset && selectMerges) {
        const isViewPosition = { isView: false, position: 0, id: assetData.id };
        const found = selectedAssetsToMerge.find(
          curringDataSearchParameters(isViewPosition, true)
        );
        if (found !== undefined) {
          setSelectedAssetsToMerge(
            selectedAssetsToMerge.filter((asset) => {
              return asset.id !== assetData.id && asset.name !== assetData.name;
            })
          );
        } else if (selectedAssetsToMerge.length < 2) {
          setSelectedAssetsToMerge([
            ...selectedAssetsToMerge,
            assetData as Dataset
          ]);
        }
      } else if (selectBulkDelete) {
        const isViewPosition = { isView: false, position: 0, id: assetData.id };
        const found = selectedAssetsToDelete.find(
          curringDataSearchParameters(isViewPosition, true)
        );
        if (found !== undefined) {
          setSelectedAssetsToDelete(
            selectedAssetsToDelete.filter((asset) => {
              return asset.id !== assetData.id && asset.name !== assetData.name;
            })
          );
        } else {
          setSelectedAssetsToDelete([
            ...selectedAssetsToDelete,
            assetData as Dataset
          ]);
        }
      } else if (assetData.id !== undefined) {
        setSelectedAssetId(assetData.id);
      }
      if (isDataset && selectMerges) {
        const isViewPosition = { isView: false, position: 0, id: assetData.id };
        const found = selectedAssetsToMerge.find(
          curringDataSearchParameters(isViewPosition, true)
        );
        if (found !== undefined) {
          setSelectedAssetsToMerge(
            selectedAssetsToMerge.filter((asset) => {
              return asset.id !== assetData.id && asset.name !== assetData.name;
            })
          );
        } else if (selectedAssetsToMerge.length < 2) {
          setSelectedAssetsToMerge([
            ...selectedAssetsToMerge,
            assetData as Dataset
          ]);
        }
      } else if (selectBulkDelete) {
        const isViewPosition = { isView: false, position: 0, id: assetData.id };
        const found = selectedAssetsToDelete.find(
          curringDataSearchParameters(isViewPosition, true)
        );
        if (found !== undefined) {
          setSelectedAssetsToDelete(
            selectedAssetsToDelete.filter((asset) => {
              return asset.id !== assetData.id && asset.name !== assetData.name;
            })
          );
        } else {
          setSelectedAssetsToDelete([
            ...selectedAssetsToDelete,
            assetData as Dataset
          ]);
        }
      } else if (assetData.id !== undefined) {
        setSelectedAssetId(assetData.id);
      }
    }
  };

  const handleContinue = async (): Promise<void> => {
    if (selectMerges) {
      GAUserEvent(
        `${userLocationVariable.current}_SELECT_${USER_TRACKING_DISPLAY_ASSETS_ACTIONS.MERGE_ACCESS}`
      );
      setViewRender({
        type: HOME_VIEW_TYPES.MERGE_DATASETS,
        payload: {
          selectedAssetsToMerge
        },
        stored: viewRender.stored
      });
    } else if (selectedAssetId !== null) {
      const isViewPosition = {
        isView: false,
        position: 0,
        id: selectedAssetId
      };
      let selectedAssetData = assets.find(
        curringDataSearchParameters(isViewPosition)
      );
      if (selectedAssetData === undefined) {
        Object.values(paginationViews).forEach((datasetVersions, index) => {
          const isFound = datasetVersions.data.find(
            curringDataSearchParameters(isViewPosition)
          );
          if (isFound !== undefined) {
            selectedAssetData = isFound;
          }
        });
      }
      if (
        isDataset &&
        selectedAssetData !== undefined &&
        isViewPosition.isView
      ) {
        const { views } = selectedAssetData as Dataset;
        if (views !== undefined && views.data.length > 0) {
          selectedAssetData = views.data[isViewPosition.position];
        }
      }
      if (viewRender.type === HOME_VIEW_TYPES.LOAD_DATASETS) {
        GAUserEvent(
          `${userLocationVariable.current}_${
            isViewPosition.isView
              ? USER_TRACKING_DISPLAY_ASSETS_ACTIONS.VERSION_SELECT
              : USER_TRACKING_DISPLAY_ASSETS_ACTIONS.DATASET_SELECT
          }`
        );
        setViewRender({
          type: HOME_VIEW_TYPES.DETAIL_DATASETS,
          payload: {
            ...viewRender?.payload,
            asset: selectedAssetData,
            isView: isViewPosition.isView,
            isInference: lastMessageIsInference(lastResponse),
            page: pagination.actual
          },
          stored: viewRender.stored
        });
      } else {
        GAUserEvent(
          `${userLocationVariable.current}_SELECT_${
            isViewPosition.isView
              ? USER_TRACKING_DISPLAY_ASSETS_ACTIONS.VERSION_SELECT
              : USER_TRACKING_DISPLAY_ASSETS_ACTIONS.MODEL_SELECT
          }`
        );
        setViewRender({
          type: HOME_VIEW_TYPES.DETAIL_MODEL,
          payload: {
            ...viewRender?.payload,
            asset: selectedAssetData,
            page: pagination.actual
          },
          stored: viewRender.stored
        });
      }
    } else if (selectBulkDelete) {
      GAUserEvent(
        `${userLocationVariable.current}_BULK_DELETE_${
          (isDataset
            ? USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET
            : USER_TRACKING_LOCATION_NAMES.UPLOAD_MODEL) as string
        }`
      );
      await exposeService
        .bulkDeleteDatasets(selectedAssetsToDelete.map((asset) => asset.id))
        .then(() => {
          resetValues();
          setLoading(true);
        })
        .catch(() => {
          showErrorAlert();
          resetValues();
        })
        .finally(() => {
          resetValues();
        });
    }
  };

  const closeModal = (): void => {
    if (isOptimise) {
      assistantService.createCountDown().catch(console.error);
    }
    setViewRender({
      type: HOME_VIEW_TYPES.CHAT,
      stored: viewRender.stored
    });
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    if (viewRender.type === HOME_VIEW_TYPES.LOAD_MODELS && !isOptimise) {
      assistantService.continueConversationAfterLoadModal(
        MODAL_ACTIONS_ID.LOAD_MODELS,
        MODAL_INTENTS.CANCEL_LOAD_MODEL,
        {}
      );
    }
  };

  const renderDatasetChecks = (
    id: string,
    assetCollection: Array<Dataset | Model>
  ): JSX.Element => {
    const found = assetCollection.find(
      curringDataSearchParameters({ id }, true)
    );
    return <SmallCheckbox found={found !== undefined} />;
  };

  const renderChecksOrInputs = (asset: Dataset | Model): JSX.Element => {
    if (selectMerges) {
      return renderDatasetChecks(asset.id, selectedAssetsToMerge);
    } else if (selectBulkDelete) {
      return renderDatasetChecks(asset.id, selectedAssetsToDelete);
    }
    return (
      <Radio
        name="model"
        id={asset.id.toString()}
        value={asset.id}
        inputRef={radioButton}
        checked={selectedAssetId === asset.id}
        readOnly
      />
    );
  };

  const generateViewFromDataset = (
    viewfillData: Dataset,
    filledDiv: JSX.Element
  ): TableRowProps['cells'] => {
    const options = {
      actionController: {
        action: handleRowSelection,
        values: viewfillData
      }
    };
    const cells: TableCellProps[] = [
      {
        index: 0,
        options,
        jsxrest: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {renderChecksOrInputs(viewfillData)}
            {filledDiv}
            <div className="model-card-view">
              <label htmlFor={viewfillData.id.toString()}>
                {viewfillData.name}
              </label>
              <div className="model-metadata">
                {generateTags(viewfillData.tags)}
                {generateSpanDate(viewfillData)}
              </div>
            </div>
          </div>
        )
      },
      {
        index: 1,
        options,
        jsxrest: null
      },
      {
        index: 2,
        options,
        jsxrest: null
      },
      {
        index: 3,
        options,
        jsxrest: (
          <CustomIcon
            className={
              selectedAssetId === viewfillData.id ? 'light-icon-no-hover' : ''
            }
            style={{
              maxWidth: '30px',
              margin: '5px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            type={
              viewfillData.visibility === ACCESS_TYPES.PRIVATE ||
              viewfillData.visibility === ACCESS_TYPES.HIDDEN
                ? ICON_TYPE.LOCK01
                : ICON_TYPE.USERS01
            }
          />
        )
      }
    ];
    return cells;
  };

  let bodyTitle = `Select a ${isDataset ? 'dataset' : 'model'}`;
  if (selectMerges) {
    bodyTitle = 'Merge datasets';
  } else if (selectBulkDelete) {
    bodyTitle = 'Bulk Delete';
  }

  const tableHeadRows: HeaderRowProps = {
    classes: 'radio-table',
    cells: [
      {
        jsxrest: <div>Name</div>
      },
      { jsxrest: null },
      { jsxrest: null },
      { jsxrest: <div>Access</div> }
    ]
  };

  //  TODO: ADD ALL STYLES
  //  TODO: FIX PAGINATION CHANGE WHEN OPENED AND REQUESTED MORE VIEWS
  const tableBodyRows: TableRowProps[] = [];
  assets.forEach((asset, index: number) => {
    let data: Dataset[] | [] = [];
    let collectionSize = 0;
    const viewIsOpen =
      viewVariants[asset.id] !== undefined && viewVariants[asset.id] !== 0;
    if (
      'views' in asset &&
      asset.views !== undefined &&
      asset.views.data !== undefined &&
      asset.views.collectionSize !== undefined
    ) {
      data = asset.views.data;
      collectionSize = asset.views.collectionSize;
    }

    const cells: TableCellProps[] = [
      {
        index,
        options: {
          actionController: {
            action: handleRowSelection,
            values: asset
          }
        },
        jsxrest: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {renderChecksOrInputs(asset as Dataset)}
            <img
              className="patternImage"
              src={ColumnTableImage}
              alt="Column Image"
            />
            <div className="model-card">
              <label htmlFor={asset.id.toString()}>{asset.name}</label>
              <div className="model-metadata">
                {generateTags(asset.tags)}
                {generateSpanDate(asset)}
              </div>
            </div>
          </div>
        )
      }
    ];
    if (collectionSize > 0) {
      cells.push({
        index,
        jsxrest: (
          <CustomButton
            customKey={`${Math.random()}`}
            variant="secondary"
            small={true}
            disableRipple={true}
            disabled={selectMerges || selectBulkDelete}
            style={{
              maxWidth: 'none',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              textTransform: 'lowercase'
            }}
            icon={{
              type:
                viewIsOpen && !selectMerges && !selectBulkDelete
                  ? ICON_TYPE.CHEVRON_UP
                  : ICON_TYPE.CHEVRON_DONW,
              position: 'right'
            }}
            onClick={() => {
              if (viewIsOpen) {
                setViewVariants({
                  ...viewVariants,
                  [asset.id]: 0
                });
              } else {
                setViewVariants({
                  ...viewVariants,
                  [asset.id]: 2
                });
              }
            }}
          >
            {viewIsOpen && !selectMerges && !selectBulkDelete
              ? 'hide versions'
              : `${collectionSize}  versions`}
          </CustomButton>
        )
      });
    } else {
      cells.push({
        index,
        options: {
          actionController: {
            action: handleRowSelection,
            values: asset
          }
        },
        jsxrest: null
      });
    }
    if (isDataset && asset.workspaceId !== undefined) {
      cells.push({
        index,
        jsxrest: (
          <CustomButton
            customKey={`${Math.random()}`}
            variant="secondary"
            disableRipple={true}
            icon={{ type: ICON_TYPE.CHAT, position: 'left' }}
            onClick={() => {
              setViewRender({
                type: HOME_VIEW_TYPES.DETAIL_DATASETS,
                payload: {
                  ...viewRender?.payload,
                  asset,
                  isView: false,
                  isInference: lastMessageIsInference(lastResponse),
                  openChat: true,
                  page: pagination.actual
                },
                stored: viewRender.stored
              });
            }}
          />
        )
      });
    } else {
      cells.push({
        index,
        options: {
          actionController: {
            action: handleRowSelection,
            values: asset
          }
        },
        jsxrest: null
      });
    }
    cells.push({
      index,
      options: {
        actionController: {
          action: handleRowSelection,
          values: asset
        }
      },
      jsxrest: (
        <CustomIcon
          className={selectedAssetId === asset.id ? 'light-icon-no-hover' : ''}
          style={{
            maxWidth: '30px',
            margin: '5px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          type={
            asset.visibility === ACCESS_TYPES.PRIVATE ||
            asset.visibility === ACCESS_TYPES.HIDDEN
              ? ICON_TYPE.LOCK01
              : ICON_TYPE.USERS01
          }
        />
      )
    });

    tableBodyRows.push({
      classes: `${selectedAssetId === asset.id ? 'select' : ''} ${
        viewRender.payload?.uploadedId === asset.id ? 'uploaded' : ''
      }`,
      cells
    });

    if (viewIsOpen && !selectMerges && !selectBulkDelete) {
      let sortedData = [...data];
      sortedData = sortedData.sort((a: Dataset, b: Dataset) => {
        return (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
      });
      const localDataset = asset as Dataset;
      if (
        localDataset?.views?.collectionSize !== undefined &&
        localDataset.views.collectionSize > 2
      ) {
        tableBodyRows.push({
          actionController: {
            action: async () => {
              if (paginationViews[asset.id] !== undefined) {
                const viewVariantDataset = paginationViews[asset.id];
                if (
                  viewVariantDataset.data.length + 2 <
                  viewVariantDataset.collectionSize
                ) {
                  if (
                    (paginationViews[asset.id] !== undefined &&
                      !paginationViews[asset.id].disableButton) ||
                    paginationViews[asset.id] === undefined
                  ) {
                    await getViewsFromDataset(asset.id);
                  }
                } else {
                  const newVariants = _.omit(paginationViews, asset.id);
                  setPaginationViews(newVariants);
                  setViewVariants({
                    ...viewVariants,
                    [asset.id]: 2
                  });
                }
              } else if (viewVariants[asset.id] >= 2) {
                if (
                  (paginationViews[asset.id] !== undefined &&
                    !paginationViews[asset.id].disableButton) ||
                  paginationViews[asset.id] === undefined
                ) {
                  await getViewsFromDataset(asset.id);
                }
              }
            }
          },
          cells: [
            {
              index: 0,
              jsxrest: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomIcon
                    style={{
                      marginLeft: '40px',
                      marginRight: '15px'
                    }}
                    className="patternImage"
                    type={ICON_TYPE.MID_CHILD}
                  />
                  <div className="model-card-view show-pattern-image">
                    <label>
                      {paginationViews[asset.id]?.buttonTitle ?? 'Show more'}
                    </label>
                  </div>
                </div>
              )
            },
            { index: 1, jsxrest: null },
            { index: 2, jsxrest: null },
            { index: 3, jsxrest: null }
          ]
        });
      }

      const midFilledDiv = (
        <CustomIcon
          style={{ marginLeft: '15px', marginRight: '15px' }}
          className="patternImage mid-pattern-image"
          type={ICON_TYPE.MID_CHILD}
        />
      );

      const endFilledDiv = (
        <CustomIcon
          style={{
            marginLeft: '15px',
            marginRight: '15px'
          }}
          className="patternImage last-pattern-image"
          type={ICON_TYPE.LAST_CHILD}
        />
      );
      for (let i = 0; i < viewVariants[asset.id]; i++) {
        const viewfillData = sortedData[i];
        if (viewfillData !== undefined) {
          let filledDiv = midFilledDiv;
          if (
            i + 1 === viewVariants[asset.id] ||
            (sortedData.length === 1 && paginationViews[asset.id] === undefined)
          ) {
            filledDiv = endFilledDiv;
          }
          tableBodyRows.push({
            cells: generateViewFromDataset(viewfillData, filledDiv)
          });
        }
      }
      if (paginationViews[asset.id]?.data?.length > 0) {
        const dataViewVariants = paginationViews[asset.id]?.data;
        dataViewVariants.forEach((element, index) => {
          let filledDiv = midFilledDiv;
          if (index === dataViewVariants.length - 1) {
            filledDiv = endFilledDiv;
          }
          tableBodyRows.push({
            cells: generateViewFromDataset(element, filledDiv)
          });
        });
      }
    }
  });

  const bodyComponent = (
    <Box>
      <Typography className="header-title color-dark">{bodyTitle}</Typography>
      <Box className="subheader-load">
        <Typography className="ft-md">
          Choose between any of the {isDataset ? 'datasets' : 'models'} stored
          in your profile in the list below
        </Typography>
      </Box>
      {loading ? (
        <Box className="spinner-box">
          <Spinner />
        </Box>
      ) : (
        <>
          <Box className="table-container">
            <CustomTable
              className="radio-table"
              header={tableHeadRows}
              body={tableBodyRows}
            />
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Pagination
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                obtainData(page);
              }}
              page={pagination.actual}
              className="pagination"
              count={pagination.total}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        </>
      )}
    </Box>
  );

  let continueText = isDataset ? 'Select dataset' : 'Continue';
  if (selectMerges) {
    continueText = 'Merge datasets';
  } else if (selectBulkDelete) {
    continueText = 'Delete';
  }

  const footerComponent = (
    <Box>
      {isDataset ? (
        <Box
          style={{
            display: 'flex',
            gap: '15px'
          }}
        >
          <input
            ref={inputUploadLinkRef}
            type="file"
            hidden
            onChange={handleFileChange}
          />
          <Select
            labelId="load-dataset-management-label"
            className="load-dataset-management-select"
            displayEmpty
            value=""
            renderValue={() => 'Add new dataset'}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              PaperProps: {
                sx: {
                  borderRadius: '16px'
                }
              }
            }}
            onChange={({ target }) => {
              switch (target.value) {
                case UPLOAD:
                  inputUploadLinkRef.current?.click();
                  break;
                case MODAL_TYPES.UPLOAD_LINK: {
                  triggersForUploadModals();
                  setModalController({ type: MODAL_TYPES.UPLOAD_LINK });
                  break;
                }
              }
            }}
          >
            <MenuItem key={UPLOAD + '0'} value={UPLOAD}>
              <CustomIcon type={ICON_TYPE.UPLOAD_CLOUD_01} />
              Upload dataset
            </MenuItem>
            <MenuItem
              key={(MODAL_TYPES.UPLOAD_LINK as string) + '1'}
              value={MODAL_TYPES.UPLOAD_LINK}
            >
              <CustomIcon type={ICON_TYPE.LINK03} />
              Link dataset
            </MenuItem>
          </Select>
          <Select
            labelId="load-dataset-management-label"
            className="load-dataset-management-select"
            displayEmpty
            value=""
            renderValue={() => 'Bulk actions'}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              PaperProps: {
                sx: {
                  borderRadius: '16px'
                }
              }
            }}
            onChange={({ target }) => {
              switch (target.value) {
                case BULK_ACTIONS.MERGE_DATASETS:
                  resetValues();
                  setSelectMerges(!selectMerges);
                  setSelectBulkDelete(false);
                  break;
                case BULK_ACTIONS.DELETE_DATASETS: {
                  resetValues();
                  setSelectMerges(false);
                  setSelectBulkDelete(!selectBulkDelete);
                  break;
                }
              }
            }}
          >
            <MenuItem
              key={`${BULK_ACTIONS.MERGE_DATASETS}0`}
              value={BULK_ACTIONS.MERGE_DATASETS}
            >
              {selectMerges ? (
                <>
                  <CustomIcon type={ICON_TYPE.UPLOAD_CLOUD_01} />
                  Cancel merge datasets
                </>
              ) : (
                <>
                  <CustomIcon type={ICON_TYPE.LINK03} />
                  Merge datasets
                </>
              )}
            </MenuItem>
            <MenuItem
              key={`${BULK_ACTIONS.DELETE_DATASETS}1`}
              value={BULK_ACTIONS.DELETE_DATASETS}
            >
              {selectBulkDelete ? (
                <>
                  <DeleteOutline />
                  Cancel delete
                </>
              ) : (
                <>
                  <CustomIcon type={ICON_TYPE.TRASH01} />
                  Delete
                </>
              )}
            </MenuItem>
          </Select>
        </Box>
      ) : null}
      <Box className="load-action-footer">
        <CustomButton variant="secondary" onClick={closeModal}>
          {selectBulkDelete || selectMerges || !isOptimise
            ? 'Exit list'
            : 'Cancel'}
        </CustomButton>
        <CustomButton
          variant="primary"
          disabled={disableContinueButton}
          onClick={handleContinue}
        >
          {continueText}
        </CustomButton>
      </Box>
    </Box>
  );

  return (
    <RenderViewComposed
      id="load-assets-component"
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default Load;
