import TableCell, { type TableCellProps } from './CustomTableCell';
import type {
  TdCellOptions,
  ActionController
} from 'common/interfaces/interfaces';
import './CustomTable.scss';

export interface TableRowProps {
  cells: TableCellProps[];
  classes?: string;
  actionController?: ActionController;
}

export interface HeaderRowProps {
  cells: Array<{
    options?: TdCellOptions;
    jsxrest: string | JSX.Element | null;
  }>;
  classes?: string;
}

export enum TableType {
  DEFAULT = 'default',
  HIGHLIGHT_1 = 'highlight-1',
  HIGHLIGHT_2 = 'highlight-2',
  HIGHLIGHT_3 = 'highlight-3'
}

interface TableProps {
  className?: string;
  type?: TableType;
  header?: HeaderRowProps;
  body: TableRowProps[];
  footer?: HeaderRowProps;
  allowHover?: boolean;
}

const SingleTableRow = ({ cells, classes }: HeaderRowProps): JSX.Element => {
  return (
    <tr className={classes !== undefined ? classes : ''}>
      {cells.map((cell, index) => {
        let classes = '';
        if (cell.options !== undefined) {
          classes =
            cell.options.classes !== undefined ? cell.options.classes : '';
        }
        return (
          <th key={`tableCell-${index}`} className={`${classes}`}>
            {cell.jsxrest}
          </th>
        );
      })}
    </tr>
  );
};

const RegularTableRow = ({
  cells,
  classes,
  actionController
}: TableRowProps): JSX.Element => {
  return (
    <tr
      className={classes !== undefined ? classes : ''}
      onMouseEnter={() => {
        if (actionController !== undefined) {
          if (actionController.onMouseOver !== undefined) {
            actionController.onMouseOver();
          }
        }
      }}
      onMouseLeave={() => {
        if (actionController !== undefined) {
          if (actionController.onMouseOut !== undefined) {
            actionController.onMouseOut();
          }
        }
      }}
      onClick={() => {
        if (actionController !== undefined) {
          if (actionController.action !== undefined) {
            if (actionController.values !== undefined) {
              actionController.action(actionController.values);
            } else {
              actionController.action();
            }
          }
        }
      }}
    >
      {cells.map((cell, index) => {
        if (cell.options?.isTdCell !== undefined) {
          return cell.options.isTdCell;
        } else {
          return (
            <TableCell
              key={`tableCell-${index}`}
              tdData={cell.tdData}
              index={cell.index}
              options={cell.options}
              jsxrest={cell.jsxrest}
            />
          );
        }
      })}
    </tr>
  );
};

const CustomTable = ({
  allowHover = true,
  className,
  header,
  body,
  footer,
  type = TableType.DEFAULT
}: TableProps): JSX.Element => {
  return (
    <table
      id={type}
      className={`${className !== undefined ? className : ''} custom-table`}
      style={{ height: '1px' }}
    >
      {header !== undefined ? (
        <thead>
          <SingleTableRow classes={header.classes} cells={header.cells} />
        </thead>
      ) : null}
      <tbody className={`${!allowHover ? 'no-hover' : ''}`}>
        {body.map((row, index) => (
          <RegularTableRow
            key={`tableRow-${index}`}
            classes={row.classes}
            cells={row.cells}
            actionController={row.actionController}
          />
        ))}
      </tbody>
      {footer !== undefined ? (
        <tfoot>
          <SingleTableRow classes={footer.classes} cells={footer.cells} />
        </tfoot>
      ) : null}
    </table>
  );
};

export default CustomTable;
