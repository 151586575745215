import { useEffect, useRef, useState, type ReactElement } from 'react';

import {
  Alert,
  Box,
  Chip,
  Divider,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  type MenuProps,
  type AlertColor
} from '@mui/material';

import type {
  Dataset,
  DatasetSchema,
  Model,
  NotifyBoxTypes,
  SaveUploadContext,
  SaveUploadedTypes,
  DisplayTableData
} from 'common/interfaces/interfaces';

import {
  ACCESS_TYPES,
  HOME_VIEW_TYPES,
  ICON_TYPE,
  MODAL_ACTIONS_ID,
  MODAL_INTENTS,
  NUMBERS_STRINGS,
  RESTRICTIONS
} from 'common/interfaces/enums';

import type { User } from 'models/User';

import CustomIcon from 'common/CustomIcon';
import AssetsForm from 'mySpace/components/assetsInputsByIntent/AssetsForm';
import Delete from 'mySpace/delete/DeleteAsset';

import { KeyboardArrowDown } from '@mui/icons-material';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';

import CloseIcon from '@mui/icons-material/Close';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import CustomButton from 'common/button/CustomButton';
import PreviewDataset from 'featureEngineering/previewDataset/PreviewDataset';
import PreviewRows from 'featureEngineering/previewRows/PreviewRows';
import SwitchTransformer from 'mySpace/components/switchTransformer/SwitchTransformer';
import type {
  ContextData,
  WarrningUpload
} from 'mySpace/uploadManager/UploadManager';
import UploadContext from 'mySpace/uploadManager/components/uploadContext/UploadContext';
import { FeatureType } from 'playground/interfaces/playground';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { GAUserEvent, formatDate } from 'utils/utils';

import {
  USER_TRACKING_DETAILS_ACTIONS,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { contextSelectionAtom } from 'mySpace/uploadManager/atoms/atomContextSelections';
import UploadAlert from 'mySpace/uploadManager/components/uploadAlert/UploadAlert';
import { checkValidityOfSchemaContext } from 'mySpace/uploadManager/utils/utils';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import { BASE_COLORS } from 'themes/foundations/Colors';
import RenderViewComposed from 'home/components/renderView/RenderView';
import AreaTextWysiwyg from 'common/areaTextWysiwyg/AreaTextWysiwyg';
import CustomTabs from 'common/CustomTabs';
import 'common/Common.scss';
import './Details.scss';

interface CONTEXT_TYPE {
  model_transparency: string;
  test_performance: { 'R-squared'?: number };
}

const llmPrototypeUrl =
  process.env.REACT_APP_LLM_PROTOTYPE_URL ??
  'https://llm-prototype-315653733071.us-central1.run.app';

const EDIT_CONTEXT = 'edit_context';

const selectMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  PaperProps: {
    sx: {
      borderRadius: '16px'
    }
  }
};

const Details = ({
  selectedAsset,
  isInference,
  isView,
  openChat
}: {
  isView: boolean;
  isInference: boolean;
  openChat: boolean;
  selectedAsset: Dataset | Model;
}): ReactElement => {
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const isOptimise = userLocationVariable.current.includes(
    USER_TRACKING_LOCATION_NAMES.OPTIMISE
  );
  const isNaturalFlow =
    isOptimise ||
    userLocationVariable.current.includes(USER_TRACKING_LOCATION_NAMES.PREDICT);
  const setMessage = useSetRecoilState(magicMessageDerived);
  const [contextSelection, setContextSelection] =
    useRecoilState(contextSelectionAtom);
  const resetContextSelection = useResetRecoilState(contextSelectionAtom);

  const assistantService = AssistantApiService.getInstance();
  const authenticationService = AuthenticationService.getInstance();
  const exposeService = ExposeService.getInstance();
  const [uploadWarning, setUploadWarning] = useState<WarrningUpload>();

  const isDataset = viewRender.type === HOME_VIEW_TYPES.DETAIL_DATASETS;
  const [editContext, setEditContext] = useState(false);
  const [asset, setAsset] = useState<Dataset | Model>(selectedAsset);
  const [name, setName] = useState<string>(asset.name ?? '');
  const [description, setDescription] = useState<string>(
    asset.description ?? ''
  );
  const [validName, setValidName] = useState<boolean>(false);

  const [preview, setPreview] = useState<DatasetSchema[]>();
  const [rowsPreview, setRowsPreview] = useState<DisplayTableData>();
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [detailPreview, setDetailPreview] = useState<string>(
    NUMBERS_STRINGS.FIRST
  );

  const [messageError, setMessageError] = useState<null | NotifyBoxTypes>(null);
  const [insightsEditMode, setInsightsEditMode] = useState<boolean>(false);

  const [sendData, setSendData] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(openChat ? 1 : 0);

  const [datasetContext, setDatasetContext] = useState<null | {
    original_name: string;
  }>(null);

  const contextData = useRef<ContextData>([]);
  const [context, setContext] = useState<null | CONTEXT_TYPE>(null);
  const [performance, setPerformance] = useState<null | number>(null);

  const user: null | User = authenticationService.userData;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<ACCESS_TYPES>(
    asset.visibility ?? ACCESS_TYPES.PRIVATE
  );
  const recivedTags = asset.tags ?? [];
  const [tags, setTags] = useState<string[]>(recivedTags);
  const [showTags, setShowTags] = useState(false);
  const [showMoreDescription, setShowMoreDescription] = useState<boolean>(true);

  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );
  const access = authenticationService.getAccess();

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const isUsersModal = user?.id === asset?.userId;
  const approveSave = insightsEditMode && name !== '' && validName;

  const filteredTags = tags.filter(
    (tag) => tag !== ACCESS_TYPES.TEMPORARILY_STORED
  );

  let tabsList = ['Preview'];
  let codedJson;
  if (isDataset && asset.workspaceId !== undefined) {
    tabsList = ['Preview', 'Ask anything'];
    codedJson = btoa(
      encodeURIComponent(
        JSON.stringify({
          dataset_id: asset.workspaceId,
          access_token: authenticationService.authToken
        })
      )
    );
  }
  const collectedSaveData: SaveUploadedTypes = {
    name,
    description,
    visibility,
    tags: filteredTags
  };

  const checkDatasetSchemaPresent = (): boolean => {
    if (isDataset && 'schema' in asset) {
      return (asset.schema ?? []).length === 0;
    }
    return true;
  };

  const datasetSchemaPresent = checkDatasetSchemaPresent();

  const [snackBar, setSnackBar] = useState<{
    status: AlertColor;
    open: boolean;
    message: string;
  }>({ status: 'info', open: false, message: '' });

  useEffect(() => {
    if (name === '' && asset.name !== undefined) {
      setName(asset.name);
    }
    if (description === '' && asset.description !== undefined) {
      setDescription(asset.description);
    }
    if (
      datasetContext === null &&
      'dataContext' in asset &&
      asset?.dataContext !== undefined
    ) {
      setDatasetContext(JSON.parse(asset.dataContext));
    }
    if (
      context === null &&
      'context' in asset &&
      asset?.context !== undefined &&
      asset.context !== ''
    ) {
      const parsedContext = JSON.parse(asset.context);
      const parsedContextValue = Object.values(parsedContext)[0];
      if (parsedContextValue !== undefined) {
        setContext(parsedContextValue as CONTEXT_TYPE);
      }
    }
    return () => {
      resetContextSelection();
    };
  }, [asset]);

  useEffect(() => {
    if (
      performance === null &&
      'performanceMetric' in asset &&
      typeof asset?.performanceMetric === 'string' &&
      context?.test_performance !== undefined
    ) {
      type testPerformance = keyof typeof context.test_performance;
      const performanceMetric = asset.performanceMetric.replace(/\\|"/g, '');
      const foundPerformance: number | undefined =
        context.test_performance[performanceMetric as testPerformance];
      if (foundPerformance !== undefined) {
        setPerformance(foundPerformance);
      }
    }
  }, [context]);

  useEffect(() => {
    if (!insightsEditMode) {
      if (nameRef.current !== null && name !== nameRef.current?.value) {
        setName(asset.name);
      }
      if (
        descriptionRef.current !== null &&
        description !== descriptionRef.current?.value
      ) {
        setDescription(asset.description);
      }
      if (
        tags.length !== recivedTags.length ||
        JSON.stringify(tags) !== JSON.stringify(recivedTags)
      ) {
        setTags(recivedTags);
      }
    }
  }, [insightsEditMode]);

  useEffect(() => {
    if (detailPreview === NUMBERS_STRINGS.SECOND && rowsPreview === undefined) {
      setPreviewLoading(false);
      setSnackBar({
        status: 'error',
        open: true,
        message: 'Dataset preview not available'
      });
    }
  }, [detailPreview]);

  const resetData = (): void => {
    setContext(null);
    setDatasetContext(null);
    setPerformance(null);
    setMessageError(null);
    setName('');
    setDescription('');
  };

  const handleSave = (
    collectedSaveData:
      | SaveUploadedTypes
      | (SaveUploadedTypes & SaveUploadContext)
  ): void => {
    setSendData(true);
    const hasDatasetId = (asset as Dataset).datasetId;
    if (hasDatasetId !== undefined) {
      //  If we find the dataset id in the asset is a view.
      exposeService
        .updateView(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            resetData();
            setAsset(data);
            setInsightsEditMode(false);
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataset ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
          } else {
            setMessageError({
              type: 'error',
              message: 'View data not found'
            });
          }
        })
        .finally(() => {
          setSendData(false);
        })
        .catch((error) => {
          const message =
            error.message !== '' ? error.message : 'Error saving dataset';
          setMessageError({ type: 'error', message });
        });
    } else if (isDataset) {
      exposeService
        .updateDataset(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            resetData();
            setAsset(data);
            setInsightsEditMode(false);
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataset ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
          } else {
            setMessageError({
              type: 'error',
              message: 'Dataset data not found'
            });
          }
        })
        .finally(() => {
          setSendData(false);
        })
        .catch((error) => {
          const message =
            error.message !== '' ? error.message : 'Error saving dataset';
          setMessageError({ type: 'error', message });
        });
    } else {
      exposeService
        .updateModel(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            resetData();
            setAsset(data);
            setInsightsEditMode(false);
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataset ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
          } else {
            setMessageError({ type: 'error', message: 'Model data not found' });
          }
        })
        .finally(() => {
          setSendData(false);
        })
        .catch((error) => {
          const message =
            error.message !== '' ? error.message : 'Error saving model';
          setMessageError({ type: 'error', message });
        });
    }
  };

  const handleSaveContext = (): void => {
    setEditContext(false);
    const outputColumns: string[] = [];
    const descriptionColumns = contextSelection.map((existingSelection) => {
      const { checked, ...restSelection } = existingSelection;
      if (checked) {
        outputColumns.push(existingSelection.name);
      }
      restSelection.description = restSelection.description.trim();
      return restSelection;
    });
    handleSave({
      ...collectedSaveData,
      outputColumns,
      descriptionColumns
    });
  };

  const handleUseModel = (): void => {
    if (editContext) {
      const isMissing = checkValidityOfSchemaContext(
        contextSelection,
        setUploadWarning
      );
      if (!isMissing) {
        handleSaveContext();
      }
    } else if (approveSave) {
      handleSave(collectedSaveData);
    } else {
      if (isDataset) {
        if (isInference && 'datasetUrl' in asset) {
          let assetUrl = asset.datasetUrl;

          if (
            isView &&
            'viewUrl' in asset &&
            typeof asset.viewUrl === 'string'
          ) {
            assetUrl = asset.viewUrl;
          }
          assistantService.sendFileLink(assetUrl);
        } else {
          assistantService.continueConversationAfterLoadModal(
            MODAL_ACTIONS_ID.LOAD_DATASETS,
            MODAL_INTENTS.CHOOSE_LOADED_DATASET,
            asset
          );
        }
      } else {
        assistantService.continueConversationAfterLoadModal(
          MODAL_ACTIONS_ID.LOAD_MODELS,
          MODAL_INTENTS.CHOOSE_LOADED_MODEL,
          asset
        );
      }
      GAUserEvent(
        `${userLocationVariable.current}_${
          (isDataset ? 'DATASET_' : 'MODEL_') +
          USER_TRACKING_DETAILS_ACTIONS.USE
        }`
      );
      setUserLocationVariable({
        ...userLocationVariable,
        current: userLocationVariable.previous,
        previous: userLocationVariable.current
      });
      setViewRender({
        type: HOME_VIEW_TYPES.CHAT,
        stored: viewRender.stored
      });
      setMessage(undefined);
    }
  };

  const onEditEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (approveSave) handleSave(collectedSaveData);
    }
  };

  const getPreview = async (): Promise<void> => {
    GAUserEvent(
      `${userLocationVariable.current}_${
        (isDataset ? 'DATASET_' : 'MODEL_') +
        USER_TRACKING_DETAILS_ACTIONS.PREVIEW
      }`
    );
    setPreviewLoading(true);
    const datasetAsset = asset as Dataset;
    const previewResponse = isView
      ? await exposeService.getViewRows(datasetAsset.datasetId, datasetAsset.id)
      : await exposeService.getDatasetRows(datasetAsset.id);
    if (
      previewResponse?.rows !== undefined &&
      previewResponse.rows.length > 0
    ) {
      setRowsPreview({
        rows: previewResponse.rows
      });
    }
    if (
      datasetAsset.schema !== undefined &&
      Array.isArray(datasetAsset.schema) &&
      datasetAsset.schema.length > 0
    ) {
      setPreview(datasetAsset.schema);
      setPreviewLoading(false);
    }
  };

  const closeModal = (): void => {
    if (isNaturalFlow) {
      assistantService.createCountDown().catch(console.error);
    }
    if (!isDataset && isNaturalFlow) {
      assistantService.continueConversationAfterLoadModal(
        MODAL_ACTIONS_ID.LOAD_MODELS,
        MODAL_INTENTS.CANCEL_LOAD_MODEL,
        {}
      );
    }
    setViewRender({
      type: HOME_VIEW_TYPES.CHAT,
      stored: viewRender.stored
    });
  };

  const handleCancel = (): void => {
    if (editContext) {
      if (messageError !== null) {
        setMessageError(null);
      }
      setEditContext(false);
      resetContextSelection();
    } else if (insightsEditMode) {
      setInsightsEditMode(false);
    } else {
      closeModal();
    }
  };

  const openDeleteModalAction = (): void => {
    setOpenDeleteModal(true);
  };

  const generateMenuList = (): JSX.Element[] => {
    const list = [
      <MenuItem
        className="menu-item"
        key="menu-item-dowload"
        disabled
        onClick={() => {
          // TODO: Implement download
        }}
      >
        <CustomIcon type={ICON_TYPE.DOWNLOAD01} />
        <Typography>Download</Typography>
      </MenuItem>
    ];

    if (isUsersModal) {
      list.push(
        <MenuItem
          className="menu-item"
          key="menu-item-edit"
          onClick={() => {
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataset ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.EDIT
              }`
            );
            setInsightsEditMode(true);
          }}
          data-cy="details-modal-edit-button"
        >
          <CustomIcon type={ICON_TYPE.EDIT02} />
          <Typography>Edit</Typography>
        </MenuItem>,
        <MenuItem
          className="menu-item"
          key="menu-item-delete"
          onClick={() => {
            openDeleteModalAction();
          }}
        >
          <CustomIcon type={ICON_TYPE.TRASH01} />
          <Typography>Delete</Typography>
        </MenuItem>
      );
    } else {
      list.push(
        <MenuItem className="menu-item" key="menu-item-hide" disabled>
          <CustomIcon type={ICON_TYPE.EYEOFF} />
          <Typography>Hide</Typography>
        </MenuItem>
      );
    }
    return list;
  };

  const generateDate = (): JSX.Element | null => {
    let date;
    if (
      isDataset &&
      'timeOfUsage' in asset &&
      asset.timeOfUsage !== undefined
    ) {
      date = formatDate(asset.timeOfUsage);
    } else if ('createdDate' in asset && asset.createdDate !== undefined) {
      date = formatDate(asset.createdDate);
    }
    return date !== undefined ? (
      <Box className="date">
        <Typography>{date}</Typography>
      </Box>
    ) : null;
  };

  const getAssetAccess = (): ReactElement => {
    if (
      Boolean(sharedResources?.defaultValue) &&
      asset.visibility !== 'private'
    ) {
      return (
        <Box className="details-access-type">
          <Chip
            className="chip"
            icon={<CustomIcon type={ICON_TYPE.USERS01} />}
            label={'Account Shared'}
          />
        </Box>
      );
    }

    return (
      <Box className="details-access-type">
        <Chip
          className="chip"
          label={'Private'}
          icon={<CustomIcon type={ICON_TYPE.LOCK01} />}
        />
      </Box>
    );
  };

  const renderTags = (): JSX.Element | null => {
    if (tags.length === 0) {
      return null;
    }
    let renderedTags = tags;
    if (!showTags && tags.length > 3) {
      renderedTags = tags.slice(0, 2);
    }
    return (
      <Box className="tags details-tags">
        {renderedTags.map((tag: string, index: number) => {
          if (tag === ACCESS_TYPES.TEMPORARILY_STORED) {
            return (
              <Chip
                className="chip temporarily-stored"
                key={`${tag}-${index}`}
                label={tag}
              />
            );
          }
          return <Chip key={`${tag}-${index}`} className="chip" label={tag} />;
        })}
        {tags.length > 3 && !showTags && (
          <Chip
            onClick={() => {
              setShowTags(true);
            }}
            key="show-more"
            className="chip"
            label={'...'}
          />
        )}
      </Box>
    );
  };

  const assetMetadata = (
    <Box className="details-section-two details-assets-block">
      {getAssetAccess()}
      {renderTags()}
      {generateDate()}
      <Box className="asset-procedence">
        <Typography>{isDataset ? 'Uploaded' : 'Assistant'}</Typography>
      </Box>
    </Box>
  );

  const generatePreviewTables = (): ReactElement => {
    return (
      <Box className="display-column">
        <Box className="details-head">
          {assetMetadata}
          <SwitchTransformer
            detailPreview={detailPreview}
            setDetailPreview={setDetailPreview}
          />
        </Box>
        <Box className="details-block">
          <Box className="details-preview-block">
            {detailPreview === NUMBERS_STRINGS.FIRST &&
              preview !== undefined && (
                <PreviewDataset displayTableData={preview} />
              )}
            {detailPreview === NUMBERS_STRINGS.SECOND &&
            rowsPreview !== undefined ? (
              <PreviewRows displayTableData={rowsPreview} />
            ) : (
              previewLoading && <LoadingDots />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const generateDescription = (): ReactElement => {
    let description = asset?.description;
    if (description?.length > 300) {
      description = showMoreDescription
        ? `${description.substring(0, 300)}...`
        : description;
      return (
        <Box className="asset-description">
          <AreaTextWysiwyg
            descriptionRef={descriptionRef}
            readOnly={true}
            value={description ?? ''}
          />
          <CustomButton
            variant="secondary"
            onClick={() => {
              setShowMoreDescription(!showMoreDescription);
            }}
            icon={{
              type: showMoreDescription
                ? ICON_TYPE.CHEVRON_DONW
                : ICON_TYPE.CHEVRON_UP,
              position: 'left'
            }}
          >
            {showMoreDescription ? 'Read more' : 'Show less'}
          </CustomButton>
        </Box>
      );
    }
    return (
      <Box style={{ color: BASE_COLORS.BASE_70 }}>
        <AreaTextWysiwyg
          descriptionRef={descriptionRef}
          readOnly={true}
          value={description ?? ''}
        />
      </Box>
    );
  };

  const generatePreviewButton = (): ReactElement => {
    if (preview !== undefined) {
      return generatePreviewTables();
    }
    return (
      <>
        {assetMetadata}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <CustomIcon
            style={{
              maxWidth: '40px',
              width: '40px',
              margin: '5px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            type={ICON_TYPE.TABLE}
          />
          <CustomButton
            variant="secondary"
            style={{
              marginTop: '10px',
              width: 'max-content'
            }}
            onClick={getPreview}
          >
            {previewLoading ? <LoadingDots /> : 'Preview Dataset'}
          </CustomButton>
        </Box>
      </>
    );
  };

  const openDataTransformationView = (): void => {
    // If user chooses to edit a dataset version (view), the asset context (that holds dataset id and url) is empty string
    const payload = {
      datasetId: '',
      originalDatasetId: '',
      datasetUrl: '',
      dataManagementFlow: true,
      previousPayload: viewRender.payload
    };

    if (asset.context !== null && asset.context !== '') {
      // We are working with dataset
      const assetContextObj = JSON.parse(asset.context);
      payload.datasetId = assetContextObj.dataset?.dataset_id ?? '';
      payload.originalDatasetId =
        assetContextObj.dataset?.original_dataset_id ?? '';
      payload.datasetUrl = 'datasetUrl' in asset ? asset.datasetUrl : '';
    } else {
      // We are working with dataset version (view)
      payload.datasetId = asset.id;
      payload.originalDatasetId = 'datasetId' in asset ? asset.datasetId : '';
      payload.datasetUrl = 'viewUrl' in asset ? (asset.viewUrl as string) : '';
    }

    setViewRender({
      type: HOME_VIEW_TYPES.TRANSFORMATION,
      payload,
      stored: viewRender.stored
    });
  };

  const openCrossTabsView = (): void => {
    const datasetAsset = asset as Dataset;
    const numericalCategoricalSchema = datasetAsset.schema?.reduce(
      (acumulator: string[], e: DatasetSchema) => {
        if (
          e.dataValue === FeatureType.NUMERICAL ||
          e.dataValue === FeatureType.CATEGORICAL ||
          e.dataValue === FeatureType.BINARY_CATEGORICAL
        ) {
          return [...acumulator, e.name];
        }
        return acumulator;
      },
      []
    );
    const payload = {
      datasetId: datasetAsset.id,
      mainGroup: numericalCategoricalSchema,
      ...viewRender?.payload
    };
    if (isView) {
      payload.viewId = datasetAsset.id;
    }
    setViewRender({
      type: HOME_VIEW_TYPES.CROSS_TABS,
      payload,
      stored: viewRender.stored
    });
  };

  const headerComponent = (
    <Box id="details-assets-component__header">
      <Typography className="header-title">
        {isDataset ? 'Dataset' : 'Modal'}
      </Typography>
      <CloseIcon
        style={{
          marginLeft: 'auto',
          cursor: 'pointer'
        }}
        onClick={handleCancel}
      />
    </Box>
  );

  let bodyComponent = (
    <>
      <Box id="details-assets-component__body" className="render-view__body">
        <CustomTabs
          tabsList={tabsList}
          selectedTab={currentTab}
          customTabChangeAction={(ev, value) => {
            setCurrentTab(value);
          }}
        />
        <Box
          sx={
            currentTab === 1 && codedJson !== undefined
              ? { display: 'none' }
              : {
                  height: 'calc(100% - 60px)' // 60px is the height of the tabs and the combination of buttons for description
                }
          }
        >
          <Box className="detail-asset-content">
            <Typography className="asset-title">{asset?.name}</Typography>
          </Box>
          <Box className="details-content-wrapp">
            <Box
              className="details-section-one"
              style={{
                height: isDataset ? '100%' : 'max-content',
                maxHeight: asset?.description !== undefined ? '100%' : '0%'
              }}
            >
              {asset?.description !== undefined ? generateDescription() : null}
              {isDataset ? generatePreviewButton() : null}
              {!isDataset ? (
                <Box className="details-sub-sections">
                  <Box className="details-assets-block">
                    <Typography className="details-sub-block-header">
                      MODEL
                    </Typography>
                    {'type' in asset && asset?.type !== undefined ? (
                      <Box className="insight-sub-block">
                        <Typography className="insight-label">
                          Model type
                        </Typography>
                        <Typography>{asset?.type}</Typography>
                      </Box>
                    ) : null}
                    {performance !== null ? (
                      <Box className="insight-sub-block">
                        <Typography className="insight-label">
                          Model precision
                        </Typography>
                        <Typography>{(+performance).toFixed(2)}</Typography>
                      </Box>
                    ) : null}
                    {'percentageGain' in asset &&
                    asset?.percentageGain !== undefined ? (
                      <Box className="insight-sub-block">
                        <Typography className="insight-label">
                          Comparision
                        </Typography>
                        <Typography>
                          {asset?.percentageGain.toFixed(2)}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                  <Box className="details-assets-block">
                    <Typography className="details-sub-block-header">
                      DATASET
                    </Typography>
                    {datasetContext?.original_name !== undefined ? (
                      <Box className="insight-sub-block">
                        <Typography className="insight-label">
                          Trained dataset
                        </Typography>
                        <Typography>{datasetContext.original_name}</Typography>
                      </Box>
                    ) : null}
                    {'importantFeatures' in asset &&
                    asset?.importantFeatures !== undefined &&
                    Array.isArray(asset?.importantFeatures) ? (
                      <Box className="insight-sub-block">
                        <Typography className="insight-label">
                          Important features
                        </Typography>
                        <Typography>
                          {asset?.importantFeatures.join(', ')}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {codedJson !== undefined ? (
          <Box
            sx={
              currentTab === 0
                ? { display: 'none' }
                : {
                    height: 'calc(100% - 48px)' // 48px is the height of the tabs alone
                  }
            }
          >
            <iframe src={`${llmPrototypeUrl}/?t=${codedJson}`}></iframe>
          </Box>
        ) : null}
      </Box>
    </>
  );

  if (editContext && (asset as Dataset).schema !== undefined) {
    bodyComponent = (
      <Box id="details-assets-component__body">
        <UploadContext
          preview={(asset as Dataset).schema ?? []}
          contextData={contextData}
        />
      </Box>
    );
  } else if (insightsEditMode) {
    bodyComponent = (
      <Box id="details-assets-component__body">
        <AssetsForm
          inputControllers={{
            stateName: { name, setName, nameRef, validName, setValidName },
            stateDescription: { description, setDescription, descriptionRef },
            stateAccess: { access, visibility, setVisibility },
            stateTags: [tags, setTags],
            shared: Boolean(sharedResources?.defaultValue)
          }}
          onEnter={onEditEnter}
          disableInputs={sendData}
          messageError={messageError}
        />
      </Box>
    );
  }
  const footerComponent = (
    <Box
      id="details-assets-component__footer"
      className={`${
        !editContext && !insightsEditMode
          ? 'justify-space-between'
          : 'details-edit-footer'
      }`}
    >
      {!editContext && !insightsEditMode ? (
        <Box className="details-footer-left">
          <Select
            labelId="details-settings"
            className="details-select"
            displayEmpty
            value=""
            renderValue={() => 'Settings'}
            IconComponent={KeyboardArrowDown}
            MenuProps={selectMenuProps}
          >
            {generateMenuList()}
          </Select>
          {isDataset && !isNaturalFlow ? (
            <Select
              labelId="data-management-label"
              className="details-select"
              disabled={currentTab === 1}
              displayEmpty
              value=""
              renderValue={() => 'Data Management'}
              IconComponent={KeyboardArrowDown}
              MenuProps={selectMenuProps}
              onChange={({ target }) => {
                switch (target.value) {
                  case HOME_VIEW_TYPES.CROSS_TABS:
                    GAUserEvent(
                      `${userLocationVariable.current}_DATASET_${USER_TRACKING_DETAILS_ACTIONS.CROSSTABS_ACCESS}`
                    );
                    openCrossTabsView();
                    break;
                  case HOME_VIEW_TYPES.TRANSFORMATION:
                    GAUserEvent(
                      `${userLocationVariable.current}_DATASET_${USER_TRACKING_DETAILS_ACTIONS.FEAT_ENG_ACCESS}`
                    );
                    openDataTransformationView();
                    break;
                  case EDIT_CONTEXT: {
                    if (insightsEditMode) {
                      setInsightsEditMode(false);
                    }
                    if (
                      'schema' in asset &&
                      asset.schema !== undefined &&
                      asset.schema.length > 0
                    ) {
                      const builtContextSchema = asset.schema.map(
                        (schemaValues) => {
                          const foundCheck = asset.targetColumns?.find(
                            (checkedNames) =>
                              schemaValues.name.toLowerCase() ===
                              checkedNames.toLowerCase()
                          );
                          return {
                            name: schemaValues.name,
                            description: schemaValues.description ?? '',
                            checked: foundCheck !== undefined
                          };
                        }
                      );
                      setContextSelection(builtContextSchema);
                      setEditContext(true);
                    }
                    break;
                  }
                }
              }}
            >
              <MenuItem
                key={HOME_VIEW_TYPES.TRANSFORMATION + '0'}
                value={HOME_VIEW_TYPES.TRANSFORMATION}
                disabled={datasetSchemaPresent}
              >
                <CustomIcon type={ICON_TYPE.DATA_TRANSFORMATION} />
                Data Transformation
              </MenuItem>
              <MenuItem
                key={EDIT_CONTEXT + '1'}
                value={EDIT_CONTEXT}
                disabled={editContext || isView}
              >
                <CustomIcon type={ICON_TYPE.EDIT_CONTEXT} />
                Edit context
              </MenuItem>
              <MenuItem
                key={HOME_VIEW_TYPES.CROSS_TABS + '2'}
                value={HOME_VIEW_TYPES.CROSS_TABS}
              >
                <CustomIcon type={ICON_TYPE.CROSS_TABS} />
                Cross tabs
              </MenuItem>
            </Select>
          ) : null}
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ status: 'info', open: false, message: '' });
          }}
        >
          <Alert severity={snackBar.status}>{snackBar.message}</Alert>
        </Snackbar>
        {isNaturalFlow || insightsEditMode || editContext ? (
          <CustomButton
            key="cancel-button"
            variant="secondary"
            onClick={handleCancel}
          >
            Cancel
          </CustomButton>
        ) : (
          <CustomButton
            key="previous-button"
            variant="secondary"
            style={{
              gap: '10px',
              maxHeight: '46px'
            }}
            disabled={currentTab === 1}
            onClick={() => {
              if (isDataset) {
                setViewRender({
                  type: HOME_VIEW_TYPES.LOAD_DATASETS,
                  payload: { page: viewRender.payload.page },
                  stored: viewRender.stored
                });
              } else {
                setViewRender({
                  type: HOME_VIEW_TYPES.LOAD_MODELS,
                  payload: { page: viewRender.payload.page },
                  stored: viewRender.stored
                });
              }
            }}
          >
            <CustomIcon type={ICON_TYPE.ARROW_LEFT} />
            Back to listings
          </CustomButton>
        )}
        {editContext || isNaturalFlow || insightsEditMode ? (
          <CustomButton
            disabled={
              !isOptimise &&
              !editContext &&
              insightsEditMode &&
              (!validName || sendData)
            }
            onClick={handleUseModel}
          >
            {insightsEditMode || editContext
              ? 'Save'
              : `Use this ${isDataset ? 'Dataset' : 'Model'}`}
            {sendData && (
              <>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  style={{ margin: '0px 10px' }}
                />
                <LoadingDots />{' '}
              </>
            )}
          </CustomButton>
        ) : null}
      </Box>
    </Box>
  );

  return (
    <>
      <RenderViewComposed
        id="details-assets-component"
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      {openDeleteModal && (
        <Delete
          messageError={[messageError, setMessageError]}
          closeDeleteModal={() => {
            setOpenDeleteModal(false);
          }}
        />
      )}
      {uploadWarning !== undefined ? (
        <UploadAlert
          title={uploadWarning.title}
          body={uploadWarning.body}
          allowContinue={uploadWarning.allowContinue}
          continueUpload={() => {
            setUploadWarning(undefined);
            handleSaveContext();
          }}
          returnToProcess={() => {
            setUploadWarning(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export default Details;
