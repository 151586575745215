import type { ICON_TYPE } from 'common/interfaces/enums';
import type {
  DatasetSchema,
  SchemaValues,
  SendTransformation,
  DisplayTableData
} from 'common/interfaces/interfaces';
import type { RefObject } from 'react';

export enum TRANSFORM_COLUMN {
  DEFAULT = 'DEFAULT',
  NEW_COLUMN = 'NEW_COLUMN',
  EDIT_COLUMN = 'EDIT_COLUMN'
}

export enum SUB_MENU {
  DISREGARD_COLUMN = 'DISREGARD_COLUMN',
  TRANSFORMATION = 'TRANSFORMATION',
  FILTER_COLUMN = 'FILTER_COLUMN',
  ADD_GROUP_CATEGORIES = 'ADD_GROUP_CATEGORIES',
  NEW_COLUMN = 'COMBINE_NUMERICAL_COLUMNS',
  CHANGE_COLUMN = 'CHANGE_COLUMN_TYPE'
}

export enum MENU {
  CHANGE_COLUMN = 'CHANGE_COLUMN',
  COLUMN_SETTINGS = 'COLUMN_SETTINGS',
  DATA_TRANSFORMATION = 'DATA_TRANSFORMATION',
  COMBINE_COLUMNS = 'COMBINE_COLUMNS',
  GROUP_CATEGORIES = 'GROUPING_IN_CATEGORIES',
  FILTER_COLUMN = 'FILTER_COLUMN'
}

export interface CollectSaveDataTextual {
  column: string;
  type: string;
  params?: {
    length?: number;
    type?: TEXTUAL_GROUPING_TYPE;
    position?: TEXTUAL_GROUPING;
    new_column_name: string;
    numerical: boolean;
    update_column: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export interface ModifiedVariables {
  disregard: FeatureSubMenu[SUB_MENU.DISREGARD_COLUMN];
  edited: FeatureSubMenu[SUB_MENU.CHANGE_COLUMN];
  added: FeatureSubMenu[SUB_MENU.ADD_GROUP_CATEGORIES];
}

export type FeatureSubMenu = Record<
  SUB_MENU,
  | boolean
  | string
  | number
  | string[]
  | { action: string; column: string; type: string }
  | { params: SendTransformation['params'] }
>;

export type FeatureMenu = Record<MENU, undefined | FeatureSubMenu>;

export type FeatureState = Record<string, undefined | FeatureMenu>;

export interface SelectedTransformationColumn {
  name: string;
  index: number;
  type: string;
  data: SchemaValues;
}

export interface SelectedMenu {
  icon: ICON_TYPE;
  text: string;
  selected: MENU;
  columnData: SelectedTransformationColumn;
}

export type TransformDataBySubMenuParameters = (
  selected: MENU,
  subMenu: SUB_MENU,
  values?: SendTransformation,
  newSchema?: DatasetSchema
) => void;

export interface IColumnSettingsProps {
  selectedMenu: SelectedMenu;
  setSelectedMenu: (
    value: React.SetStateAction<SelectedMenu | undefined>
  ) => void;
  updateTransformState: TransformDataBySubMenuParameters;
  removeColumnGroup?: () => void;
  selectedMenuKey?: FeatureSubMenu;
  rowsNames?: DisplayTableData['keys'];
  originalPreview?: DatasetSchema[];
}

export type MutableNumericalRefs = RefObject<{ value: string } | undefined>;

export interface NumericalColumn {
  first?: MutableNumericalRefs;
  second?: MutableNumericalRefs;
  colName?: MutableNumericalRefs;
}

export type NumericalType = NumericalColumn & {
  placeHolder: { first: string; second: string; colName: string };
};

export const numberRegex = /^-?[0-9]+\.?[0-9]*$/;
export const columnNameRegex = /^[-a-zA-Z0-9_ ]*$/;

export enum SAVE_DATA_TEXTUAL_PARAMS {
  NUMERICAL = 'numerical',
  UPDATE_COLUMN = 'update_column',
  NEW_COLUMN_NAME = 'new_column_name',
  LENGTH = 'length',
  TYPE = 'type',
  POSITION = 'position'
}

export enum TEXTUAL_GROUPING {
  FIRST = 'first',
  LAST = 'last'
}

export enum TEXTUAL_GROUPING_TYPE {
  WORDS = 'words',
  LETTERS = 'letters'
}
