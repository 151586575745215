import { Box, Chip, Typography } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { ICON_TYPE } from 'common/interfaces/enums';
import type { SampleData, TdCellOptions } from 'common/interfaces/interfaces';
import SmallCheckbox from 'common/smallCheckbox/SmallCheckbox';
import { useState, type ReactElement } from 'react';

export interface TableCellProps {
  tdData?: SampleData;
  index: number;
  options?: TdCellOptions;
  jsxrest?: JSX.Element | null;
}

const CustomTableCell = ({
  tdData,
  index,
  options = {
    withChip: true,
    limitVisibility: false,
    contextOfDisplayedData: undefined
  },
  jsxrest
}: TableCellProps): ReactElement | null => {
  const [listOpenContextuals, setListingOpenContextuals] = useState<number[]>(
    []
  );
  const [listLimitedDataVisualization, setListLimitedDataVisualization] =
    useState<boolean[]>([]);
  const { checked, classes, contextOfDisplayedData, actionController } =
    options;
  const hasClass = `${typeof classes === 'string' ? classes : ''}`;
  let { withChip, limitVisibility } = options;
  limitVisibility = limitVisibility !== undefined && limitVisibility;
  withChip = withChip !== undefined && withChip;
  if (jsxrest !== undefined) {
    return (
      <td
        className={hasClass}
        onClick={() => {
          if (actionController !== undefined) {
            const { action, values } = actionController;
            if (values !== undefined && action !== undefined) {
              action(values);
            } else if (action !== undefined) {
              action();
            }
          }
        }}
      >
        {jsxrest}
      </td>
    );
  } else if (checked !== undefined && actionController !== undefined) {
    const { action, values } = actionController;
    return (
      <td
        className={hasClass}
        onClick={() => {
          if (action !== undefined) {
            action(values);
          }
        }}
      >
        <SmallCheckbox found={checked} />
      </td>
    );
  }

  const renderEyeLimitedVisibility = (
    index: number,
    isVisible?: boolean
  ): JSX.Element => {
    if (isVisible !== undefined && isVisible) {
      return (
        <Box
          className="chip cursor-pointer"
          onClick={() => {
            setListLimitedDataVisualization({
              ...listLimitedDataVisualization,
              [index]: false
            });
          }}
        >
          <CustomIcon type={ICON_TYPE.EYEOFF} />
        </Box>
      );
    }
    return (
      <Box
        className="chip cursor-pointer"
        onClick={() => {
          setListLimitedDataVisualization({
            ...listLimitedDataVisualization,
            [index]: true
          });
        }}
      >
        <CustomIcon type={ICON_TYPE.EYE} />
      </Box>
    );
  };

  if (tdData !== undefined) {
    let condition = Array.isArray(tdData) && tdData.length;
    let tdChild: SampleData | JSX.Element[] | ReactElement | [] = tdData;
    const isVisible = listLimitedDataVisualization[index] ?? false;
    const isLongWord = typeof tdData === 'string' && !tdData.includes(' ');
    const isLongText =
      limitVisibility && typeof tdData === 'string' && tdData.length > 70;
    let tdClass = `${withChip ? 'preview-flex' : 'first-column'}`;

    if (limitVisibility) {
      tdClass += isVisible ? ' unLimitedVisibility' : ' limitedVisibility';
    }

    if (Array.isArray(tdData) && typeof condition === 'number') {
      const isOpen =
        listOpenContextuals !== undefined
          ? listOpenContextuals.includes(index)
          : false;
      tdClass = `${tdClass} ${
        isOpen || condition === 3 ? 'previewCategoricalOpen' : ''
      }`;
      if (tdData.length > 3) {
        const newtdChild: JSX.Element[] = [];
        if (tdData.length > 3) {
          if (isOpen) {
            condition = tdData.length;
          } else {
            condition = 3;
          }
        }

        for (let i = 0; i < condition; i++) {
          const data = `${tdData[i] as string} `;
          newtdChild.push(
            <Chip className="chip" key={`preview-cell-${i}`} label={data} />
          );
        }
        if (isOpen || condition !== tdData.length) {
          newtdChild.push(
            <Chip
              className="chip"
              key={`preview-cell-${condition}`}
              label={
                isOpen ? (
                  <u>show less</u>
                ) : (
                  <u>show more ({tdData.length - 3})</u>
                )
              }
              onClick={(event) => {
                event.stopPropagation();
                if (isOpen && listOpenContextuals !== undefined) {
                  setListingOpenContextuals(
                    listOpenContextuals.filter((item) => item !== index)
                  );
                } else if (listOpenContextuals !== undefined) {
                  setListingOpenContextuals([...listOpenContextuals, index]);
                }
              }}
            />
          );
        }
        tdChild = newtdChild;
      } else {
        tdChild = tdData.map((data, index) => {
          return (
            <Chip
              className="chip"
              key={`preview-cell-${index}`}
              label={`${data as string}`}
            />
          );
        });
      }
    } else if (withChip) {
      if (isLongText) {
        tdChild = (
          <Box className="chip chip-style">
            {!isVisible ? tdData.slice(0, 70) + '...' : tdData}
          </Box>
        );
      } else {
        tdChild = <Chip className="chip" label={tdData as string} />;
      }
    }

    return (
      <td
        className={hasClass}
        onClick={() => {
          if (actionController !== undefined) {
            const { action, values } = actionController;
            if (values !== undefined && action !== undefined) {
              action(values);
            } else if (action !== undefined) {
              action();
            }
          }
        }}
      >
        <Box
          className={`${tdClass}`}
          style={
            isLongWord ? { wordBreak: 'break-all' } : { wordBreak: 'keep-all' }
          }
        >
          {tdChild as ReactElement}
          {contextOfDisplayedData !== undefined && (
            <Typography className="preview-context">
              {contextOfDisplayedData}
            </Typography>
          )}
          {isLongText ? renderEyeLimitedVisibility(index, isVisible) : null}
        </Box>
      </td>
    );
  }
  return null;
};

export default CustomTableCell;
