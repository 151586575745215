import type {
  DatasetSchema,
  SendCombineColumns,
  SendTransformation,
  DisplayTableData
} from 'common/interfaces/interfaces';
import {
  MENU,
  SAVE_DATA_TEXTUAL_PARAMS,
  SUB_MENU,
  TEXTUAL_GROUPING,
  TEXTUAL_GROUPING_TYPE,
  type FeatureMenu,
  type FeatureState,
  type FeatureSubMenu,
  type ModifiedVariables,
  type SelectedTransformationColumn
} from 'featureEngineering/featureEngineeringInterface';
import {
  FeatureType,
  FeatureTypeBigQuery
} from 'playground/interfaces/playground';
import type { Dispatch, SetStateAction } from 'react';

export const transformGroupingTextual = ({
  text,
  length,
  type,
  position
}: {
  text: string;
  length: number;
  type: TEXTUAL_GROUPING_TYPE;
  position: TEXTUAL_GROUPING;
}): string => {
  const lengthPositive = length < 0 ? -(-length) : length;
  const lengthNegative = length < 0 ? length : -length;
  const slicebasedonPosition = (
    recivedText: string | string[],
    isWords = false
  ): string | string[] => {
    if (position === TEXTUAL_GROUPING.LAST) {
      return recivedText.slice(lengthNegative);
    }
    if (isWords) {
      return recivedText.slice(0, lengthPositive);
    }
    return recivedText.slice(0, lengthPositive);
  };

  let word;
  if (type === TEXTUAL_GROUPING_TYPE.WORDS) {
    word = slicebasedonPosition(text.split(' '), true);
  } else {
    word = slicebasedonPosition(text);
  }

  if (Array.isArray(word)) return word.join(' ');
  return word;
};

const paramsIsNotSaveDataTextual = (param: string): boolean => {
  return (
    param !== SAVE_DATA_TEXTUAL_PARAMS.NUMERICAL &&
    param !== SAVE_DATA_TEXTUAL_PARAMS.UPDATE_COLUMN &&
    param !== SAVE_DATA_TEXTUAL_PARAMS.NEW_COLUMN_NAME &&
    param !== SAVE_DATA_TEXTUAL_PARAMS.LENGTH &&
    param !== SAVE_DATA_TEXTUAL_PARAMS.TYPE &&
    param !== SAVE_DATA_TEXTUAL_PARAMS.POSITION
  );
};

const updatePreview = (
  transformationState: FeatureState,
  previewData: DatasetSchema,
  name: string,
  selected: SelectedTransformationColumn,
  params?: SendTransformation['params'],
  isAdding = false
): DatasetSchema => {
  const selectedData = selected.data;
  let dataValue =
    params !== undefined ? FeatureType.CATEGORICAL : selectedData.dataValue;
  // TODO: Numerical have params undefined. Add control for data values depending on the tranformation type
  if (
    params?.keep_values !== undefined &&
    selectedData.dataValue === 'numerical'
  ) {
    dataValue = FeatureType.NUMERICAL;
  } else if (
    selectedData.dataValue === 'numerical' &&
    selected?.type === FeatureTypeBigQuery.INTEGER
  ) {
    dataValue = FeatureType.NUMERICAL;
  }

  let sample: string | string[] = [];

  const sampleData = selectedData.sampleData;

  // The edited var is only used for the CHANGE_COLUMN as it's an async call
  let { edited } = getPreviewModified(transformationState, selected.name);
  edited =
    typeof edited === 'object' &&
    'action' in edited &&
    edited.action === SUB_MENU.CHANGE_COLUMN.toLowerCase();

  if (edited && !isAdding) {
    if (params?.newType !== undefined) {
      dataValue = params.newType;
      const newPreviewData: DatasetSchema = {
        name,
        type: selected.type,
        dataValue,
        sampleData
      };
      return newPreviewData;
    }
  } else {
    let countNumeric = 0;
    if (params !== undefined) {
      // IF PARAMS EXISTS IT MEANS THAT IT IS A GROUPING TRANSFORMATION
      if (params.numerical === true) {
        Object.keys(params).forEach((paramKeys: string) => {
          if (Array.isArray(sample) && paramsIsNotSaveDataTextual(paramKeys)) {
            countNumeric += 1;
            sample.push(paramKeys);
          }
        });

        if (countNumeric === 2) dataValue = FeatureType.BINARY_CATEGORICAL;
      } else if (
        typeof sampleData === 'string' &&
        params.length !== undefined &&
        params.type !== undefined &&
        params.position !== undefined
      ) {
        sample.push(
          transformGroupingTextual({
            text: sampleData,
            length: params.length,
            type: params.type,
            position: params.position
          })
        );
      } else if (params.keep_values !== undefined) {
        sample = params.keep_values;
      }
    }
    const newPreviewData: DatasetSchema = {
      name: params?.new_column_name ?? name,
      type: selected.type,
      dataValue,
      sampleData: sample
    };
    return newPreviewData;
  }
  return previewData;
};

export const transformPreviewData = (
  transformationState: FeatureState,
  preview: DatasetSchema[],
  selected: SelectedTransformationColumn,
  values: SendTransformation | undefined,
  isAdding = false,
  isRessetParams: { originalPreview: undefined | DatasetSchema[] } = {
    originalPreview: undefined
  }
): DatasetSchema[] => {
  if (
    isRessetParams.originalPreview !== undefined &&
    isRessetParams.originalPreview.length !== 0
  ) {
    if (isAdding) {
      preview = preview.filter((prv) => prv.name !== selected.name);
    } else {
      const original = isRessetParams.originalPreview.find(
        (prv) => prv.name === selected.name
      );
      if (original !== undefined) {
        const first = preview.slice(0, selected.index);
        const last = preview.slice(selected.index + 1);
        return [...first, original, ...last];
      }
    }
  } else if (values !== undefined) {
    const firstPart = preview.slice(
      0,
      isAdding ? selected.index + 1 : selected.index
    );
    const newPreview: DatasetSchema[] = [
      ...firstPart,
      updatePreview(
        transformationState,
        preview[selected.index],
        values.column,
        selected,
        values.params,
        isAdding
      ),
      ...preview.slice(selected.index + 1, preview.length)
    ];
    return newPreview;
  }
  return preview;
};

export const transformRowsPreviewData = (
  rowsPreview: DisplayTableData,
  selected: SelectedTransformationColumn,
  values: SendTransformation | undefined,
  isAdding = false,
  isRessetParams: { originalRows: undefined | DisplayTableData } = {
    originalRows: undefined
  }
): DisplayTableData => {
  const name = values?.column ?? selected.name;
  let keys = rowsPreview.keys;
  if (Array.isArray(keys) && keys.length > 0) {
    if (
      values === undefined &&
      isRessetParams.originalRows !== undefined &&
      isRessetParams.originalRows.keys !== undefined &&
      isRessetParams.originalRows.keys.length !== 0
    ) {
      if (isAdding) {
        keys = keys.filter((key) => key !== selected.name);
      }
    } else if (values !== undefined && isAdding) {
      const firstPart = keys.slice(0, selected.index + 1);
      let valueAsName = name;
      if (
        values?.params !== undefined &&
        values.params.new_column_name !== undefined
      ) {
        valueAsName = values.params.new_column_name;
      }
      keys = [
        ...firstPart,
        valueAsName,
        ...keys.slice(selected.index + 1, keys.length)
      ];
    }
  }

  const rows = rowsPreview.rows.map((rowData, index) => {
    if (
      values === undefined &&
      rowData !== undefined &&
      rowData[selected.name] !== undefined &&
      isRessetParams.originalRows !== undefined &&
      isRessetParams.originalRows.rows[index] !== undefined
    ) {
      const originalRowsIndexed = isRessetParams.originalRows.rows[index];
      if (!isAdding && originalRowsIndexed !== undefined) {
        rowData[selected.name] = originalRowsIndexed[selected.name];
        return { ...rowData };
      } else {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete rowData[selected.name];
        return rowData;
      }
    } else if (
      values?.params !== undefined &&
      rowData !== undefined &&
      (rowData[values.column] !== undefined || isAdding)
    ) {
      if (
        values.action === SUB_MENU.NEW_COLUMN &&
        values.params.new_column_name !== undefined
      ) {
        const { formula } = values.params.operation;
        return {
          ...rowData,
          [values.params.new_column_name]: formula ?? 'operation'
        };
      }
      const sampleData = rowData[values.column];
      if (
        selected.data.dataValue === FeatureType.NUMERICAL &&
        values.params.numerical === true
      ) {
        let numericalColumnIsAdded = false;
        Object.keys(values.params).forEach((paramKeys: string) => {
          const generateRow = (): void => {
            if (
              values?.params?.new_column_name !== undefined &&
              rowData !== undefined
            ) {
              rowData[values.params.new_column_name] = paramKeys;
            } else {
              rowData = { ...rowData, [values.column]: paramKeys };
            }
          };

          if (
            values.params !== undefined &&
            paramsIsNotSaveDataTextual(paramKeys)
          ) {
            const isInclusive: boolean =
              values.params[paramKeys].includes(sampleData);
            if (isInclusive && !numericalColumnIsAdded) {
              numericalColumnIsAdded = true;
              generateRow();
              return;
            }
            const sortedValues = [...values.params[paramKeys], sampleData].sort(
              (a, b) => a - b
            );
            const belongsToRange = sortedValues.findIndex(
              (element) => element === sampleData
            );
            if (!numericalColumnIsAdded && belongsToRange === 1) {
              numericalColumnIsAdded = true;
              generateRow();
              return;
            }

            if (!numericalColumnIsAdded) {
              // Sets default to null value until it finds the one that fits the range
              if (
                values.params.new_column_name !== undefined &&
                rowData !== undefined
              ) {
                rowData[values.params.new_column_name] = null;
              } else {
                rowData = { ...rowData, [values.column]: null };
              }
            }
          }
        });
      } else if (
        selected.data.dataValue === FeatureType.TEXTUAL &&
        values.params.numerical === false &&
        typeof sampleData === 'string' &&
        values.params.length !== undefined &&
        values.params.type !== undefined &&
        values.params.position !== undefined
      ) {
        const transformedValue = transformGroupingTextual({
          text: sampleData,
          length: values.params.length,
          type: values.params.type,
          position: values.params.position
        });
        if (values.params.new_column_name !== undefined) {
          rowData[values.params.new_column_name] = transformedValue;
        } else {
          return { ...rowData, [selected.name]: transformedValue };
        }
      }
    }
    return rowData;
  });

  return {
    keys,
    rows
  };
};

interface transformationSubMenuUpdated {
  column: FeatureSubMenu;
  initialModifyedVariables: ModifiedVariables;
}

type transformActionType = (extend: boolean) => FeatureState;

export const transformAction = (
  transformationData: FeatureState,
  columnName: string,
  menuKey: MENU,
  subMenuKey: SUB_MENU,
  values: FeatureSubMenu[SUB_MENU]
): transformActionType => {
  // This function builds a transformedData object with the new values
  const modifiedColumn = (transformationData[columnName] ?? {}) as FeatureMenu;
  const modifiedDatas = (modifiedColumn[menuKey] ?? {}) as FeatureSubMenu;

  let newTransform = {
    ...transformationData,
    [columnName]: {
      ...modifiedColumn,
      [menuKey]: {
        ...modifiedDatas,
        [subMenuKey]: values
      }
    }
  };

  return (extend = false) => {
    // This function takes the parent functions context created on the new Transformation related data
    // Either extends the context by operating on it or returns it
    if (!extend) {
      return newTransform;
    }
    if (subMenuKey in modifiedDatas) {
      const selectedColumnMenus = newTransform[columnName] as FeatureMenu;
      const selectedSubmenus = selectedColumnMenus[menuKey] as FeatureSubMenu;
      const transformDataValue = selectedSubmenus[SUB_MENU.DISREGARD_COLUMN];

      if (transformDataValue !== undefined) {
        const { [subMenuKey]: deletedSubMenuKey, ...restSubMenu } =
          selectedSubmenus;
        const updatedTransformMenu = restSubMenu;
        if (Object.keys(updatedTransformMenu).length === 0) {
          const { [menuKey]: deletedMenuKey, ...restMenu } =
            selectedColumnMenus;
          newTransform[columnName] = restMenu as FeatureMenu;
          if (Object.keys(restMenu).length === 0) {
            const { [columnName]: deletedMenuKey, ...restTransform } =
              newTransform;
            newTransform = restTransform;
          }
        } else {
          newTransform = {
            ...transformationData,
            [columnName]: {
              ...modifiedColumn,
              [menuKey]: {
                ...updatedTransformMenu
              }
            }
          };
        }
      }
    }
    return newTransform;
  };
};

export const getTransformationState = (
  transformedState: FeatureState
): Array<SendTransformation | SendCombineColumns> => {
  let newTransfomed: Array<SendTransformation | SendCombineColumns> = [];
  Object.keys(transformedState).forEach((column: string) => {
    let isDisregardedData: SendTransformation | undefined;
    const columnData = transformedState[column];
    const localTransformation = [];
    if (columnData !== undefined) {
      for (const menu in columnData) {
        const menuData = columnData[menu as MENU];
        for (const subMenu in menuData) {
          const subMenuData = menuData[
            subMenu as SUB_MENU
          ] as SendTransformation;
          switch (subMenu) {
            case SUB_MENU.DISREGARD_COLUMN:
              isDisregardedData = subMenuData;
              localTransformation.push(subMenuData);
              break;
            case SUB_MENU.NEW_COLUMN:
              if (subMenuData.params !== undefined) {
                const newColumnTransformation: SendCombineColumns = {
                  action: subMenuData.action.toLowerCase(),
                  column: subMenuData.column,
                  type: subMenuData.type,
                  params: {
                    formula: subMenuData.params.operation.formula,
                    new_column_name: subMenuData.params
                      .new_column_name as string,
                    other_columns: subMenuData.params.operation.otherColumns
                  }
                };
                localTransformation.push(newColumnTransformation);
              }
              break;
            case SUB_MENU.CHANGE_COLUMN:
            case SUB_MENU.ADD_GROUP_CATEGORIES:
            case SUB_MENU.FILTER_COLUMN:
              localTransformation.push(subMenuData);
              break;
          }
        }
      }
    }
    if (isDisregardedData !== undefined) {
      newTransfomed = [...newTransfomed, isDisregardedData];
    } else {
      newTransfomed = [...newTransfomed, ...localTransformation];
    }
  });
  return newTransfomed;
};

export const getModifiedVariablesFromTranformation = (
  keysOfTransformedColumn: string[],
  transformationPayload: transformationSubMenuUpdated
): transformationSubMenuUpdated['initialModifyedVariables'] => {
  if (keysOfTransformedColumn !== undefined) {
    keysOfTransformedColumn.forEach((typeOfTransformation) => {
      switch (typeOfTransformation) {
        case SUB_MENU.DISREGARD_COLUMN:
          transformationPayload.initialModifyedVariables = {
            ...transformationPayload.initialModifyedVariables,
            disregard: transformationPayload.column[typeOfTransformation]
          };
          break;
        case SUB_MENU.CHANGE_COLUMN:
        case SUB_MENU.FILTER_COLUMN: {
          transformationPayload.initialModifyedVariables = {
            ...transformationPayload.initialModifyedVariables,
            edited: transformationPayload.column[typeOfTransformation]
          };
          break;
        }
        case SUB_MENU.ADD_GROUP_CATEGORIES:
        case SUB_MENU.NEW_COLUMN:
          transformationPayload.initialModifyedVariables = {
            ...transformationPayload.initialModifyedVariables,
            added: transformationPayload.column[typeOfTransformation]
          };
          break;
        default:
      }
    });
  }
  return transformationPayload.initialModifyedVariables;
};

export const getPreviewModified = (
  transformationState: FeatureState,
  columnName: string
): ModifiedVariables => {
  const initialModifyedVariables: ModifiedVariables = {
    disregard: false,
    edited: false,
    added: false
  };
  const updatedColumn = transformationState[columnName];
  if (updatedColumn !== undefined) {
    let updatedMenu;
    if (updatedColumn[MENU.COLUMN_SETTINGS] !== undefined) {
      updatedMenu = updatedColumn[MENU.COLUMN_SETTINGS];
    } else if (updatedColumn[MENU.GROUP_CATEGORIES] !== undefined) {
      updatedMenu = updatedColumn[MENU.GROUP_CATEGORIES];
    } else if (updatedColumn[MENU.COMBINE_COLUMNS] !== undefined) {
      updatedMenu = updatedColumn[MENU.COMBINE_COLUMNS];
    } else if (updatedColumn[MENU.CHANGE_COLUMN] !== undefined) {
      updatedMenu = updatedColumn[MENU.CHANGE_COLUMN];
    } else if (updatedColumn[MENU.FILTER_COLUMN] !== undefined) {
      updatedMenu = updatedColumn[MENU.FILTER_COLUMN];
    }
    if (updatedMenu !== undefined) {
      return getModifiedVariablesFromTranformation(Object.keys(updatedMenu), {
        column: updatedMenu,
        initialModifyedVariables
      });
    }
  }
  return initialModifyedVariables;
};

interface previewController {
  preview: DatasetSchema[] | undefined;
  setPreview: Dispatch<SetStateAction<DatasetSchema[] | undefined>>;
}
interface rowsController {
  rowsPreview: DisplayTableData | undefined;
  setRowsPreview: Dispatch<SetStateAction<DisplayTableData | undefined>>;
}

export const modifyOriginalPreviewDatas = (
  transformationState: FeatureState,
  { preview, setPreview }: previewController,
  { rowsPreview, setRowsPreview }: rowsController,
  selected: SelectedTransformationColumn,
  values: SendTransformation | undefined,
  transformSubMenuKey: SUB_MENU
): void => {
  const isAdding =
    transformSubMenuKey === SUB_MENU.ADD_GROUP_CATEGORIES ||
    transformSubMenuKey === SUB_MENU.NEW_COLUMN;
  if (
    values?.column !== undefined &&
    values?.params !== undefined &&
    preview !== undefined &&
    preview.length > 0
  ) {
    setPreview(
      transformPreviewData(
        transformationState,
        preview,
        selected,
        values,
        isAdding
      )
    );
  }

  if (
    rowsPreview?.rows !== undefined &&
    rowsPreview?.rows.length > 0 &&
    values?.params !== undefined
  ) {
    setRowsPreview({
      ...rowsPreview,
      ...transformRowsPreviewData(rowsPreview, selected, values, isAdding)
    });
  }
};
